import React from "react";
import propTypes from "prop-types";
import { Row, Col, Typography, Card, Table, Tag, Collapse } from "antd";
import moment from "moment";
import { useTranslation } from "react-i18next";
import "../translations/index";

import Header from "./header";
import colors from "../styles/colors";

const { Column } = Table;

const QuizDetails = props => {
	const { quiz, setViewDetails, allQuiz } = props;
	const { t } = useTranslation();
	const getCorrectAnswers = answers => {
		const corrects = answers.reduce((ac, el) => {
			if (el.answer.points >= 1) ac++;
			return ac;
		}, 0);
		return corrects;
	};
	const getScore = (total, corrects) => {
		const score = (corrects / total) * 100;
		return score.toFixed(0);
	};
	return (
		<>
			<Header goBack={() => setViewDetails(null)} title={quiz?.pathbookTitle[quiz.langCode]} />
			<Row gutter={[15, 15]} style={{ marginBottom: 10 }}>
				<Col md={8} xs={24}>
					<Card style={{ backgroundColor: colors.stat4 }}>
						<Typography.Title level={3} style={{ color: "#fff" }}>
							{t("quizDetails.attemps")}
						</Typography.Title>
						<Typography.Title style={{ margin: 0, fontSize: 25, color: "#fff" }}>{allQuiz.length}</Typography.Title>
					</Card>
				</Col>
				<Col md={8} xs={24}>
					<Card style={{ backgroundColor: colors.stat5 }}>
						<Typography.Title level={3} style={{ color: "#fff" }}>
							{t("quizDetails.lastDate")}
						</Typography.Title>
						<Typography.Title style={{ margin: 0, fontSize: 25, color: "#fff" }}>{moment(quiz.createdAt?.toDate()).format("ll")}</Typography.Title>
					</Card>
				</Col>
				<Col md={8} xs={24}>
					<Card style={{ backgroundColor: colors.stat6 }}>
						<Typography.Title level={3} style={{ color: "#fff" }}>
							{t("quizDetails.qualification")}
						</Typography.Title>
						<Typography.Title style={{ margin: 0, fontSize: 25, color: "#fff" }}>{quiz.score}</Typography.Title>
					</Card>
				</Col>
			</Row>
			{allQuiz.map((q, i) => (
				<Col key={i} span={24}>
					<Collapse defaultActiveKey={["1"]}>
						<Collapse.Panel
							header={
								<div
									style={{
										display: "flex",
									}}>
									<div style={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
										<div>
											Preguntas: <span style={{ fontWeight: "bold" }}>{q.details.length}</span>
										</div>
										<div>
											Correctas: <span style={{ fontWeight: "bold" }}>{getCorrectAnswers(q.details)}</span>{" "}
										</div>
										<div>
											{" "}
											Calificacion:{" "}
											<span style={{ fontWeight: "bold" }}>{getScore(q.details.length, getCorrectAnswers(q.details))}%</span>
										</div>
										<div style={{ width: "20%", textAlign: "end", color: colors.primary, fontWeight: "bold" }}>{`${moment(
											q.createdAt?.toDate()
										).format("ll")}`}</div>
									</div>
								</div>
							}
							key={1 + i}>
							<Table dataSource={q.details}>
								<Column dataIndex="question" key="question" title={t("quizDetails.question")} />
								<Column
									dataIndex="answer"
									key="answer"
									render={answer => {
										return answer.answer;
									}}
									title={t("quizDetails.answer")}
								/>
								<Column
									dataIndex="answer"
									key="points"
									render={answer => (
										<>
											<Tag color={answer.points > 0 ? "green" : "red"}>
												{answer.points > 0 ? t("quizDetails.correct") : t("quizDetails.incorrect")}
											</Tag>
										</>
									)}
								/>
							</Table>
						</Collapse.Panel>
					</Collapse>
				</Col>
			))}
		</>
	);
};

QuizDetails.propTypes = {
	allQuiz: propTypes.array,
	quiz: propTypes.object,
	setViewDetails: propTypes.func.isRequired,
};

export default QuizDetails;
