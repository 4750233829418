import _ from "lodash";
import async from "async";
import axios from "axios";
import { firestore } from "../../firebase";
import moment from "moment";

const isReadingEvent = {
	stoppedPathbook: true,
	changedPage: true,
	finishedPathbook: true,
	startedPathbook: true,
};

///  Este metodo agrega el evento 'stoppedPathbook' si hubo inactividad de 20 minutos en la plataforma
const addingStoppedPathbook = events => {
	const orderData = _.orderBy(
		events,
		(o: any) => {
			return o.date.value;
		},
		["asc"]
	);

	let dateIndex = 0;
	if (!orderData[dateIndex]) return orderData;
	const data = [orderData[dateIndex]];
	for (const [index, value] of orderData.entries()) {
		if (moment(value.date.value).isSame(orderData[dateIndex].date.value, "day")) {
			const diff = moment(value.date.value).diff(moment(orderData[dateIndex].date.value), "minute");
			if (diff > 4 || value?.pathbook !== orderData?.[dateIndex]?.pathbook) {
				data.push({
					...orderData[dateIndex],
					event: "stoppedPathbook",
				});
			}
		}
		data.push({ ...value });
		dateIndex = index;
	}

	return data;
};
export const loadStudent = async (student: string, range: Array) => {
	const queryDates = range ? ` AND (timestamp BETWEEN ${range[0].startOf("day").valueOf()} AND ${range[1].endOf("day").valueOf()})` : "";
	const events = await axios("https://us-central1-pathbook.cloudfunctions.net/apiv2/analytics", {
		method: "POST",
		data: {
			query: `WHERE user = "${student}"` + queryDates,
		},
	});

	let schoolEvents = events.data;
	schoolEvents = addingStoppedPathbook(events.data);
	// Get Stats
	const finishedPathbook = schoolEvents.filter(x => x?.event === "finishedPathbook");
	const pathbooksRead = [...new Set(finishedPathbook.map(x => x["pathbook"]))];
	const studentsReading = [...new Set(finishedPathbook.map(x => x.user))];

	// Get Top Pathbooks
	const countingPathbooks = _.chain(finishedPathbook).countBy("pathbook").toPairs().sortBy(1);
	const topPathbooks = await async.mapSeries(
		countingPathbooks.value(),
		async.asyncify(async x => {
			const lastReads = finishedPathbook.filter(y => y.pathbook === x[0]);
			const lastRead = lastReads ? lastReads[0].date.value : null;

			const pathbookData = await firestore().doc(`pathbooks/${x[0]}`).get();
			return {
				id: x[0],
				count: x[1],
				lastRead,
				en: finishedPathbook.filter(y => y["pathbook"] === x[0] && y.lang === "en").length,
				es: finishedPathbook.filter(y => y["pathbook"] === x[0] && y.lang === "es").length,
				classification: pathbookData?.data()?.classification,
				name: pathbookData?.data()?.title?.["es"] ?? pathbookData?.data()?.title?.["en"],
				academic: pathbookData?.data()?.author?.id === "CSDa5AEH2m8bW8XsQPat",
			};
		})
	);

	topPathbooks.sort((a, b) => b.count - a.count);

	const pathbookEvents = schoolEvents.reduce((ac, el) => {
		const date = moment(el?.timestamp).format("ll");

		if (!ac[el.pathbook]) {
			ac[el.pathbook] = isReadingEvent[el.event] ? { [date]: [el] } : {};
			return ac;
		}
		if (ac[el.pathbook][date] && isReadingEvent[el.event]) ac[el.pathbook][date].push(el);
		else if (isReadingEvent[el.event]) ac[el.pathbook][date] = [el];
		return ac;
	}, {});

	const dates = schoolEvents.map(e => ({ date: moment(e.timestamp), event: e.event }));
	const activeDays = dates.reduce((a, b) => {
		if (!a[b.date.format("ll")]) {
			a[b.date.format("ll")] = { count: 1, events: [b] };
			return a;
		}
		a[b.date.format("ll")].count++;
		a[b.date.format("ll")].events.push(b);
		return a;
	}, {});
	const onlyDates = dates.map(d => d.date);
	const lastReadingDate = dates.length === 0 ? null : moment.max(onlyDates);
	const minReadingData = dates.length === 0 ? null : moment.min(onlyDates);
	// if user do not enter date
	const minDate = range ? range[0] : minReadingData;
	const maxDate = range ? range[1] : lastReadingDate;

	const ratio = pathbooksRead.length / Object.keys(activeDays).length ?? 0;
	const enPathbooks = [
		...topPathbooks["map"](x => ({
			...x,
			count: x.en,
		})).filter(x => x.count),
	];
	enPathbooks.sort((a, b) => b.count - a.count);
	const esPathbooks = [
		...topPathbooks["map"](x => ({
			...x,
			count: x.es,
		})).filter(x => x.count),
	];
	esPathbooks.sort((a, b) => b.count - a.count);
	const timeForDate = await readingTime(pathbookEvents, topPathbooks);
	return {
		state: {
			loading: false,
			reads: schoolEvents.filter(x => x.event === "finishedPathbook").length,
			interactions: schoolEvents.filter(x => x.event === "changedPage").length,

			pathbooksRead,
			studentsReading,
			ratio: ratio ? ratio.toFixed(2) : 0,
			topPathbooks,
			enPathbooks,
			esPathbooks,
			lastReadingDate,
			activeDays: Object.keys(activeDays).map(x => ({
				date: x,
				interactions: activeDays[x].count,
				readingTime: getReadingTimeFormatter(activeDays[x].events),
			})),
		},
		range: [minDate, maxDate],
		pathbookEvents,
		readingTime: timeForDate,
	};
};

const getReadingTimeFormatter = (data: array) => {
	let pause = 0;
	for (const [index, value] of data.entries()) {
		if (value.event === "stoppedPathbook") {
			if (index + 1 < data.length) pause += value.date.diff(data[index + 1].date, "second");
		}
	}
	const dates = data.map(x => x.date);
	const max = moment.max(dates);
	const min = moment.min(dates);
	const diff = max.diff(min, "second") + pause;
	const minutes = Math.trunc(diff / 60);
	const seconds = Math.trunc(diff % 60) * 0.01;
	return (minutes + seconds).toFixed(2);
};

export const readingTime = async (pathbookEvents, topPathbooks) => {
	const x = {};
	for (const pathbookID of Object.keys(pathbookEvents)) {
		const pathbookIndex = topPathbooks.findIndex(x => x.id === pathbookID);
		for (const date of Object.keys(pathbookEvents[pathbookID])) {
			const data = [...pathbookEvents[pathbookID][date]];
			const events = data.map(x => ({ event: x.event, date: moment(x.date.value) }));
			let pause = 0;
			for (const [index, value] of events.entries()) {
				if (value.event === "stoppedPathbook") {
					if (index + 1 < events.length) pause += value.date.diff(events[index + 1].date, "second");
				}
			}
			const dates = events.map(e => e.date);
			const diff = moment.max(dates).diff(moment.min(dates), "second") + pause;
			const minutes = Math.trunc(diff / 60);
			const seconds = Math.trunc(diff % 60) * 0.01;
			try {
				if (!x[date]) x[date] = {};
				let name = pathbookIndex !== -1 ? topPathbooks[pathbookIndex].name : "";
				if (!name) {
					const pathbookData = await firestore().doc(`pathbooks/${pathbookID}`).get();
					name = pathbookData.data().title["es"];
				}

				x[date][pathbookID] = { readingTime: (minutes + seconds).toFixed(2), name };
			} catch (error) {}
		}
	}
	return x;
};
