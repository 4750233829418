import React from "react";
import async from "async";
import { Modal } from "antd";
import { readString } from "react-papaparse";
import { firestore, functions } from "../../firebase";
import Redux from "../../redux";

const Upload = async f => {
	return new Promise(resolve => {
		const reader = new FileReader();
		reader.readAsText(f.file, "UTF-8");
		reader.onload = async function (evt) {
			const arr: {
				errors: Array<>,
				data: Array<{
					nombre: string,
					usuario: string,
					password: string,
					grado: string,
					grupo: string,
				}>,
			} = readString(evt.target.result, { header: true });

			// Catch Papa parse / CSV errors
			if (arr.errors.length) {
				return resolve(
					<>
						Encontramos los siguientes errores: <br />
						<b>{JSON.stringify(arr.errors)}</b>
					</>
				);
			}

			// Check for duplicates
			const entriesWithRow: Array<{ nombre: string, usuario: string, row: number }> = arr.data.map((x, y) => ({ ...x, row: y + 1 }));
			const validEntries = entriesWithRow.filter((x: { nombre: string }) => x.nombre);

			const lookup = validEntries.reduce((a, e) => {
				a[e.usuario] = ++a[e.usuario] || 0;
				return a;
			}, {});

			// Handle duplicates errors
			const duplicates = validEntries.filter(e => lookup[e.usuario]);
			if (duplicates.length) {
				return resolve(
					<>
						Encontramos los siguientes usuarios duplicados: <br />
						{duplicates.map((x, y) => (
							<div key={y}>
								<b>Linea:</b> {x.row} - <b>Nombre:</b> {x.nombre} - <b>Usuario:</b> {x.usuario}
								<br />
							</div>
						))}
					</>
				);
			}

			// Check licenses
			const school = Redux().store.getState().school;
			const schoolQuery = await firestore().doc(`schools/${school.id}`).get();
			const licensesRemaining = schoolQuery.data().licensesRemaining;

			if (validEntries.length > licensesRemaining) {
				return resolve(
					<>
						No tienes licencias suficientes. <br />
						Tu archivo tiene <b>{validEntries.length}</b> estudiantes
						<br />
						Tu escuela tiene <b>{licensesRemaining}</b> licencias disponibles
						<br />
						Contacta a Pathbooks para agregar mas
					</>
				);
			}

			// Start creating
			return async.eachOfSeries(
				validEntries,
				(entry, key, callback) => {
					const grade =
						entry.grado === "Bachillerato"
							? "B15"
							: entry.grado === "Secundaria"
							? "B"
							: entry.grado === "Primaria"
							? "A"
							: entry.grado === "Kinder"
							? "AA"
							: "";

					functions()
						.httpsCallable("school-createStudent")({
							grade,
							group: entry.grupo,
							name: entry.nombre,
							username: entry.usuario,
							password: entry.password,
							email: entry.email ?? null,
						})
						.then(() => callback())
						.catch(e => {
							callback({
								row: key + 1,
								message: e.message,
							});
						});
				},
				err => {
					if (err)
						return resolve(
							<>
								Error al agregar usuarios: <br />
								<b>Linea:</b> {err.row} <br /> <b>Mensaje:</b> {err.message}
							</>
						);

					Modal.success({
						title: "Éxito",
						content: (
							<>
								Todos tus <b>{validEntries.length}</b> estudiantes se han importado correctamente
							</>
						),
					});

					resolve();
				}
			);
		};
	});
};

export default Upload;
