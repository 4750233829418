import React, { useEffect, useState } from "react";
import { Layout, Avatar, Dropdown, Menu, Modal } from "antd";
import propTypes from "prop-types";
import styled from "styled-components";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { SettingOutlined, CloseCircleOutlined, ExclamationCircleOutlined, GlobalOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import "../translations/index";

import MenuSideBar from "./menu";
import { Title } from "../components/useChart";
import { logout } from "../redux/actions/user";
import { index } from "../redux/actions/stats";
import pathbooks from "../assets/logo_title_white.png";
import p from "../assets/logo-p.png";
import useWindowSize from "../utils/window";
import LangModal from "./langModal";

const Container = styled(Layout.Content)`
	padding: 20px;
	padding-top: 40px;
	padding-bottom: 0;
	margin-left: 200px;
	@media (max-width: 768px) {
		padding: 10px;
		padding-bottom: 0;
	}
	@media (max-width: 1200px) {
		margin-left: 80px;
	}
`;
const Content = styled.div`
	background-color: #fff;
	min-height: 85vh;
	padding: 24px;
	@media (max-width: 768px) {
		padding: 10px;
	}
`;

const Image = styled.img`
	width: 180px;
	cursor: pointer;

	@media (max-width: 1200px) {
		max-width: 50px;
		padding-top: 2px;
		padding-bottom: 2px;
	}
`;

const Page = props => {
	const school = useSelector(state => state.school);
	const stats = useSelector(state => state.stats);
	const user = useSelector(state => state.user);
	const dispatch = useDispatch();
	const history = useHistory();
	const [width] = useWindowSize();
	const { t } = useTranslation();
	const [langModal, setLangModal] = useState(false);
	const logOut = () => {
		Modal.confirm({
			title: t("page.logoutConfirm"),
			icon: <ExclamationCircleOutlined />,
			content: t("page.panelExit"),
			okText: t("continue"),
			cancelText: t("cancel"),
			onOk() {
				dispatch(logout());
			},
		});
	};
	useEffect(() => {
		dispatch(index());
	}, [dispatch, stats?.fetching]);
	useEffect(() => {
		if (!user) {
			history.push("/login");
		}
	}, [user, history]);
	let name = user?.name?.split(" ")[0];
	name = name?.charAt(0)?.toUpperCase() + name?.slice(1);

	return (
		<>
			<Layout>
				<Layout.Sider
					breakpoint={"xl"}
					collapsedWidth={80}
					style={{ overflow: "auto", height: "100vh", position: "fixed", left: 0, zIndex: 12 }}
					theme="dark">
					<br />
					<br />
					<br />
					<br />
					<MenuSideBar />
				</Layout.Sider>
				<Layout>
					<Layout.Header
						style={{
							width: "100%",
							position: "fixed",
							zIndex: 1000,
							display: "flex",
							alignItems: "center",
							justifyContent: "space-between",
							padding: 20,
							paddingLeft: 10,
						}}>
						<Image alt={"Pathbooks"} onClick={() => history.push("/")} src={width > 1200 ? pathbooks : p} width={"100%"} />
						<div style={{ height: 64, display: "flex", justifyContent: "center", alignItems: "center" }}>
							<Title level={5} style={{ color: "white", margin: 15, fontSize: "1em" }}>
								{t("hello")} <span style={{ fontWeight: "bold" }}> {name}</span>
							</Title>
							<Dropdown
								overlay={
									<Menu>
										{user?.schoolAdmin && (
											<Menu.Item
												icon={<SettingOutlined style={{ fontSize: 20, margin: "0 10px" }} />}
												onClick={() => history.push("/configuracion")}
												style={{
													display: "flex",
													alignItems: "center",
													height: 60,
													width: 200,
												}}>
												{t("page.configuration")}
											</Menu.Item>
										)}
										<Menu.Item
											icon={<GlobalOutlined style={{ fontSize: 20, margin: "0 10px" }} />}
											onClick={() => setLangModal(true)}
											style={{
												display: "flex",
												alignItems: "center",
												height: 60,
												width: 200,
											}}>
											{t("language")}
										</Menu.Item>
										<Menu.Item
											danger
											icon={<CloseCircleOutlined style={{ fontSize: 20, margin: "0 10px" }} />}
											onClick={logOut}
											style={{
												display: "flex",
												alignItems: "center",
												height: 60,
												width: 200,
											}}>
											{t("page.logout")}
										</Menu.Item>
									</Menu>
								}>
								<Avatar size="large" src={school?.logo} style={{ color: "#f56a00", backgroundColor: "#fde3cf", cursor: "pointer" }}>
									{school?.name.charAt(0)}
								</Avatar>
							</Dropdown>
						</div>
					</Layout.Header>
					<br />
					<br />
					<Container>
						<Content>{props.children}</Content>
					</Container>
					<Layout.Footer style={{ textAlign: "center" }}>{t("copyright")}</Layout.Footer>
				</Layout>
			</Layout>
			<LangModal onCancel={() => setLangModal(false)} visible={langModal} />
		</>
	);
};

Page.propTypes = {
	children: propTypes.node,
};

export default Page;
