import React, { useState, useEffect } from "react";
import { Chart, Interval, Tooltip, Point, PieChart, Line } from "bizcharts";
import { Row, Col, InputNumber, Slider, Typography } from "antd";
import propTypes from "prop-types";
import styled from "styled-components";
import EmptyStats from "../assets/EmptyStats";
import { renderReadingTime } from "../utils/readingTime";
import { useTranslation } from "react-i18next";
import "../translations/index";

const scale = {
	count: {
		min: 0,
	},
};

export const Title = styled(Typography.Text)`
	font-family: "PingFang SC";
	font-size: 18px;
	color: black;
	text-align: left;
	line-height: 20px;
`;

export const Description = styled(Typography.Text)`
	padding-top: 8px;
	font-family: "PingFang SC";
	font-size: 12px;
	color: grey;
	text-align: left;
	line-height: 16px;
`;

export const BarGraph = props => {
	const [showData, setShowData] = useState([]);
	const [covers, setCovers] = useState({});
	const [range, setRange] = useState(props.showAll ? props.data.length : 20);
	const { t } = useTranslation();
	useEffect(() => {
		if (props.data.length < 20) setRange(props.data.length);
		if (props.type === "pathbooks") {
			const images = {};
			props.data.forEach(data => (images[data.name] = data.cover));
			setCovers(images);
		}
	}, [props.data, props.type]);

	useEffect(() => {
		const data = [...props.data];
		const show = data.splice(0, range);
		setShowData(show);
	}, [props.data, range]);

	const empty = showData.reduce((ac, el) => {
		if (ac) return el.count === 0;
		return false;
	}, true);

	return (
		<div style={{ padding: "20px 0 " }}>
			<Row gutter={[5, 5]} justify="space-between">
				<Col md={8} xs={24}>
					<h3>{props.label}</h3>
				</Col>
				{props.data.length > 1 && (
					<>
						<Col md={12} xs={18}>
							<Slider
								marks={{ 1: "1", [props.data.length]: props.data.length }}
								max={props.data.length}
								min={1}
								onChange={setRange}
								value={typeof range === "number" ? range : 0}
							/>
						</Col>
						<Col md={4} xs={6}>
							<InputNumber max={props.data.length4} min={1} onChange={setRange} style={{ margin: "0 16px" }} value={range} />
						</Col>
					</>
				)}
			</Row>

			<Chart appendPadding={[20, 0, 0, 0]} autoFit data={showData} height={400} interactions={["active-region"]} scale={scale}>
				<Interval
					color="name"
					position="name*count"
					tooltip={[
						"name*count",
						(text, num) => {
							return {
								name: props.type === "pathbooks" ? t("useChart.wasReas") : t("useChart.studentRead"),
								value:
									props.type === "pathbooks"
										? `${num} ${num === 1 ? t("useChart.time") : t("useChart.times")}`
										: `${num} ${num === 1 ? t("useChart.book") : t("useChart.books")}`,
							};
						},
					]}
				/>
				<Tooltip />
				{props.type === "pathbooks" && (
					<>
						<Point
							position="name*count"
							shape={[
								"name",
								name => {
									return ["image", covers[name]];
								},
							]}
							size={40}
							tooltip={false}
						/>
					</>
				)}
			</Chart>
			{empty && (
				<div
					style={{
						position: "absolute",
						top: "28%",
						left: "40%",
						display: "flex",
						flexDirection: "column",
						justifyContent: "center",
						alignItems: "center",
					}}>
					<EmptyStats height={200} width={200} />
					<p style={{ color: "#E98B00", fontSize: 15, fontWeight: "bold" }}>{t("useChart.emptyGraph")}</p>
				</div>
			)}
		</div>
	);
};

BarGraph.propTypes = {
	data: propTypes.array,
	label: propTypes.string,
	showAll: propTypes.bool,
	type: propTypes.string,
};

export const CircularGraph = props => {
	return (
		<PieChart
			angleField="count"
			colorField="name"
			data={props.data || []}
			description={{
				visible: !!props.description,
				text: props.description,
			}}
			label={{
				visible: true,
				type: props.type === "languages" ? "outer" : "inner",
				offset: 20,
				formatter: value => `${(value.percent * 100).toFixed(2)}%`,
			}}
			radius={0.8}
			title={{
				visible: !!props.title,
				text: props.title,
			}}
			tooltip={{
				visible: true,
				formatter: ({ name, count }) => {
					return {
						name: name,
						value: props.type === "languages" ? `${count} lecturas` : `Fue leído ${count > 1 ? count + " veces" : count + " vez"}`,
					};
				},
			}}
		/>
	);
};

CircularGraph.propTypes = {
	data: propTypes.array,
	description: propTypes.string,
	title: propTypes.string,
	type: propTypes.string,
};

export const InteractionGraph = props => {
	return (
		<Chart
			autoFit
			data={props.data.map(x => ({ ...x, readingTime: Math.floor(x.readingTime) }))}
			height={500}
			padding={[10, 20, 50, 40]}
			scale={{ value: { min: 0 } }}>
			<Line
				position="date*readingTime"
				tooltip={{
					fields: ["readingTime", "interactions"],
					callback: (x, y) => {
						return {
							value: renderReadingTime(x),
							name: `${y} interacciones en`,
						};
					},
				}}
			/>
			<Point
				position="date*readingTime"
				tooltip={{
					fields: ["readingTime", "interactions"],
					callback: (x, y) => {
						return {
							value: renderReadingTime(x),
							name: `${y} interacciones en`,
						};
					},
				}}
			/>
			<Tooltip showCrosshairs />
		</Chart>
	);
};

InteractionGraph.propTypes = {
	data: propTypes.array,
};
