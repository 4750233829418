const SchoolState = (state = null, action) => {
	switch (action.type) {
		case "SET_SCHOOL":
			return action.payload;
		case "UPDATE_SCHOOL":
			return {
				...state,
				...action.payload,
			};
		case "REMOVE_SCHOOL":
			return null;
		default:
			return state;
	}
};

export default SchoolState;
