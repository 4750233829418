import async from "async";
import { Modal as RawModal } from "antd";
import { functions } from "../../firebase";

const disableStudents = (students: Array) => {
	return new Promise(resolve => {
		RawModal.confirm({
			title: "Desactivar Estudiantes",
			content: `Esto quitara por completo a ${students.length} estudiante(s). Favor de confirmar`,
			okText: "Desactivar",
			okButtonProps: {
				danger: true,
			},
			cancelText: "Cancelar",
			onOk: async () => {
				await async.each(
					students,
					async.asyncify(async student => {
						await functions().httpsCallable("school-disableStudent")({ student });
					})
				);
				resolve();
			},
		});
	});
};

export default disableStudents;
