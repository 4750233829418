import React from "react";
import { Button, Input, Form, Row, message, Typography } from "antd";
import { useHistory } from "react-router-dom";
import styled from "styled-components";

import { auth } from "../firebase";
import { useDispatch } from "react-redux";
import { login } from "../redux/actions/user";

import { useTranslation } from "react-i18next";
import "../translations/index";

import bg from "../assets/bg.png";
import colors from "../styles/colors";
import logo from "../assets/logo.png";

const Page = styled.div`
	width: 100vw;
	height: 100vh;
	background-image: url("${bg}");
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	display: flex;
	justify-content: center;
	align-items: center;
`;

const Modal = styled.div`
	width: 520px;
	background-color: white;
	border-radius: 10px;
	padding: 50px;
	-webkit-box-shadow: 0 0 40px 0 rgba(0, 0, 0, 0.28);
	-moz-box-shadow: 0 0 40px 0 rgba(0, 0, 0, 0.28);
	box-shadow: 0 0 40px 0 rgba(0, 0, 0, 0.28);
`;

function Login() {
	const history = useHistory();
	const dispatch = useDispatch();
	const [type, setType] = React.useState("login");
	const [loading, setLoading] = React.useState(false);
	const { t } = useTranslation();

	const finish = values => {
		setLoading(true);
		if (type === "reset") {
			return auth()
				.sendPasswordResetEmail(values.email)
				.then(async () => {
					setLoading(false);
					return message.success("Revisa tu correo para poder reiniciar tu contraseña");
				})
				.catch(error);
		} else {
			return auth()
				.signInWithEmailAndPassword(values.email, values.password)
				.then(async () => {
					await dispatch(login());
					return history.push("/");
				})
				.catch(error);
		}
	};

	const error = e => {
		setLoading(false);
		if (e.code === "auth/invalid-email") {
			return message.error("El correo parece no valido");
		}
		if (e.code === "auth/user-not-found") {
			return message.error("Este usuario no existe");
		}
		return message.error(e.message);
	};

	return (
		<Page>
			<Modal>
				<img alt={"Pathbooks"} src={logo} style={{ marginLeft: 38 }} width={"80%"} />
				<div style={{ marginBottom: type !== "login" ? 10 : 38 }} />
				{type !== "login" && (
					<div style={{ marginBottom: 10 }}>
						<Typography.Text style={{ fontSize: 13, color: colors.primary, opacity: 0.9 }}>{t("login.schoolResetPassword")}</Typography.Text>
					</div>
				)}
				<Form layout={"vertical"} onFinish={finish}>
					<Form.Item label={t("email")} name={"email"} rules={[{ required: true, type: "email", message: t("login.emailRequirid") }]}>
						<Input placeholder={t("login.enterEmail")} />
					</Form.Item>
					{type === "login" ? (
						<Form.Item label={t("password")} name={"password"} rules={[{ required: true, message: t("login.passwordRequired") }]}>
							<Input placeholder={t("login.enterPassword")} type={"password"} />
						</Form.Item>
					) : null}
					<Row>
						<Button block htmlType={"submit"} loading={loading} type={"primary"}>
							{type === "login" ? t("login.login") : t("login.sendEmail")}
						</Button>
					</Row>
					<Row justify="center" style={{ marginTop: 10 }}>
						<Button
							onClick={() => {
								setType(type === "login" ? "reset" : "login");
							}}
							style={{ padding: 0 }}
							type={"link"}>
							{type === "login" ? t("login.forgetPassword") : t("login.returnLogin")}
						</Button>
					</Row>
				</Form>
			</Modal>
		</Page>
	);
}

export default Login;
