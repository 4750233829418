import React, { useState, useEffect, useCallback } from "react";
import { Row, Col, Typography, Menu, Input, Pagination, Spin, Select } from "antd";
import { AppstoreOutlined, EyeInvisibleOutlined, FilterOutlined, InfoCircleOutlined } from "@ant-design/icons";
import { useSelector } from "react-redux";
import { pathbooksIndex } from "../utils/algolia";

import { useTranslation } from "react-i18next";
import "../translations/index";

import { firestore } from "../firebase";
import Pathbook from "../components/pathbook";
import moment from "moment";
import EmptyLock from "../assets/EmptyLock";
import Header from "../components/header";
import colors from "../styles/colors";
const { Search } = Input;

const LockedPathbooks = () => {
	const [tab, setTab] = useState("catalogue");
	const [catalogue, setCatalogue] = useState([]);
	const [lockedPathbooks, setLockedPathbooks] = useState({});
	const [currentPage, setCurrentPage] = useState(0);
	const [paginationInfo, setPaginationInfol] = useState({ nbHits: 0, hitsPerPage: 0 });
	const [search, setSearch] = useState("");
	const { t } = useTranslation();
	const mapText = {
		en: t("english"),
		es: t("spanish"),
		pt: t("portuguese"),
		de: t("german"),
		it: t("italian"),
		fr: t("french"),
		ebook: t("lockedPathbooks.ebooks"),
		A: t("lockedPathbooks.kinderPrimary"),
		B: t("middleSchool"),
		B15: t("highSchool"),
		any: t("lockedPathbooks.anyGrade"),
	};
	const [filters, setFilters] = useState({
		languages: "es",
		classification: "",
		type: "",
	});
	const [loading, setLoading] = useState(true);
	const school = useSelector(state => state.school);
	const user = useSelector(state => state.user);

	const languages = ["es", "en", "it", "pt", "de", "fr"];
	const academicLevel = ["A", "B", "B15", "ebook"];

	const onSearch = useCallback(
		async e => {
			let filtersStr = Object.keys(filters).reduce((ac, key) => {
				if (!ac && filters[key]) return filters[key] === "A" ? "(classification:A OR classification:AA) " : `${key}: ${filters[key]} `;
				else if (filters[key])
					return filters[key] === "A" ? ac + " AND (classification:A OR classification:AA) " : ac + ` AND ${key}: ${filters[key]} `;
				return ac;
			}, "");
			filtersStr = filtersStr ? filtersStr + "AND NOT classification:C" : "NOT classification:C";
			const results = await pathbooksIndex.search(search, { page: e ? 0 : currentPage, filters: filtersStr });
			const resultsInMyLang = results.hits
				.filter(x => x["es"])
				.map(x => ({
					...x,
					...x["es"],
				}));
			if (e) setCurrentPage(0);
			setPaginationInfol({ nbHits: results.nbHits, hitsPerPage: results.hitsPerPage });
			setCatalogue(resultsInMyLang);
			setLoading(false);
		},
		[currentPage, search, filters]
	);

	const load = () => {
		if (!school) return false;
		const run = async () => {
			const locked = await firestore().collection(`schools/${school.id}/lockedPathbooks`).get();
			const data = {};
			for (const x of locked.docs) {
				const pathbook = await x.data().pathbook.get();
				const author = await pathbook.data().author.get();
				data[x.id] = { id: x.id, ...pathbook.data(), author: author.data().name };
			}
			setLockedPathbooks(data);
		};
		run().catch();
	};

	const toggleLockedPathbook = id => {
		const lock = async () => {
			const ref = firestore().doc(`pathbooks/${id}`);
			const pathbook = await ref.get();
			const author = await pathbook.data().author.get();
			await firestore().doc(`schools/${school.id}/lockedPathbooks/${id}`).set({ pathbook: ref, timestamp: moment().toDate() });
			setLockedPathbooks({
				...lockedPathbooks,
				[id]: { id, ...pathbook.data(), author: author.data().name },
			});
		};
		const unlock = async () => {
			await firestore().doc(`schools/${school.id}/lockedPathbooks/${id}`).delete();
			const x = { ...lockedPathbooks };
			delete x[id];
			setLockedPathbooks(x);
		};
		lockedPathbooks[id] ? unlock().catch() : lock().catch();
	};
	useEffect(load, [school]);
	useEffect(() => {
		setLoading(true);
		onSearch();
	}, [currentPage, onSearch, filters, search]);

	return (
		<>
			<Row align={"middle"} gutter={[15, 15]} justify={"space-between"}>
				<Col>
					<Header hideArrow title={tab === "catalogue" ? t("lockedPathbooks.catalogue") : t("lockedPathbooks.booksLocked")} />
				</Col>
				<Col>
					<Menu mode="horizontal" onClick={e => setTab(e.key)} selectedKeys={[tab]}>
						<Menu.Item icon={<AppstoreOutlined />} key="catalogue">
							{t("lockedPathbooks.library")}
						</Menu.Item>
						<Menu.Item icon={<EyeInvisibleOutlined />} key="locked">
							{t("lockedPathbooks.booksLocked")}
						</Menu.Item>
					</Menu>
				</Col>
			</Row>
			{tab === "catalogue" ? (
				<>
					<Row align="middle" justify="center">
						<Search
							enterButton
							onChange={e => {
								setSearch(e.target.value);
								setCurrentPage(0);
							}}
							onSearch={onSearch}
							placeholder={t("lockedPathbooks.searchPathbook")}
						/>
					</Row>
					{/* Filters  */}
					<Row gutter={[15, 15]} style={{ marginTop: 8 }}>
						<Col style={{ alignItems: "center" }}>
							<FilterOutlined style={{ marginRight: 9, color: colors.primary, fontSize: 14 }} />
							<Typography.Text style={{ marginRight: 10, color: colors.primary, fontSize: 14 }}>
								{t("lockedPathbooks.filterIdiom")}
							</Typography.Text>
							<Select
								bordered={false}
								onChange={value => {
									setFilters(s => ({ ...s, languages: value }));
									setCurrentPage(0);
								}}
								style={{ color: colors.darkGray, width: 110, fontSize: 14 }}
								value={filters.languages}>
								{languages.map(lang => (
									<Select.Option key={lang} value={lang}>
										{mapText[lang]}
									</Select.Option>
								))}
							</Select>
						</Col>
						{(user?.role === "Teacher" || user?.role === "Principal") && (
							<Col style={{ alignItems: "center" }}>
								<InfoCircleOutlined style={{ marginRight: 7, color: colors.primary, fontSize: 14 }} />
								<Typography.Text className="text-md" style={{ marginRight: 10, color: colors.primary, fontSize: 14 }}>
									{t("lockedPathbooks.filterLevel")}
								</Typography.Text>
								<Select
									bordered={false}
									className="text-md"
									defaultValue={""}
									onChange={value => {
										if (value === "ebook") return setFilters(s => ({ ...s, type: value, classification: "" }));
										setFilters(s => ({ ...s, classification: value, type: "" }));
										setCurrentPage(0);
									}}
									style={{ color: colors.darkGray, width: 150, fontSize: 14 }}>
									{academicLevel.map(level => (
										<Select.Option key={level} value={level}>
											{mapText[level]}
										</Select.Option>
									))}
									<Select value={""}>{mapText["any"]} </Select>
								</Select>
							</Col>
						)}
					</Row>
					<Row gutter={[20, 20]} style={{ marginTop: 20 }}>
						{catalogue.map(x => {
							return (
								<Col key={x.id} lg={8} md={12} xs={24} xxl={6}>
									<Pathbook
										author={x.author}
										cover={x.cover}
										id={x.id}
										isAdmin={user.schoolAdmin}
										lock={!lockedPathbooks[x.id]}
										summary={x.summary}
										title={x.title}
										toggleLockedPathbook={() => toggleLockedPathbook(x.id)}
									/>
								</Col>
							);
						})}
						{!catalogue.length && (
							<Typography.Text style={{ fontWeight: "bold", margin: "auto", marginTop: 10 }}>
								{t("lockedPathbooks.resoultsFound")}
							</Typography.Text>
						)}
						{loading && (
							<div
								style={{
									display: "flex",
									justifyContent: "center",
									height: "70vh",
									width: "100%",
									alignItems: "center",
									flexDirection: "column",
								}}>
								<Spin size={"large"} />
							</div>
						)}
					</Row>
					<Row justify="end" style={{ marginTop: 20 }}>
						<Pagination
							current={currentPage + 1}
							hideOnSinglePage
							onChange={n => setCurrentPage(n - 1)}
							pageSize={paginationInfo.hitsPerPage}
							pageSizeOptions={[]}
							total={paginationInfo.nbHits}
						/>
					</Row>
				</>
			) : (
				<>
					{!!Object.keys(lockedPathbooks).length ? (
						<Row gutter={[15, 15]} style={{ marginTop: 20 }}>
							{Object.keys(lockedPathbooks).map(x => (
								<Col key={x} lg={8} md={12} xs={24} xxl={6}>
									<Pathbook
										author={lockedPathbooks[x].author}
										cover={lockedPathbooks[x].cover["es"]}
										id={x}
										isAdmin={user.schoolAdmin}
										lock={!lockedPathbooks[x]}
										summary={lockedPathbooks[x].summary["es"]}
										title={lockedPathbooks[x].title["es"]}
										toggleLockedPathbook={() => toggleLockedPathbook(x)}
									/>
								</Col>
							))}
						</Row>
					) : (
						<div
							style={{
								display: "flex",
								justifyContent: "center",
								height: "70vh",
								alignItems: "center",
								flexDirection: "column",
							}}>
							<EmptyLock style={{ maxWidth: "100%" }} width={500} />
							<Typography.Text style={{ fontWeight: "bold", fontSize: 18, color: "#222" }}>{t("lockedPathbooks.emptyList")}</Typography.Text>
						</div>
					)}
				</>
			)}
		</>
	);
};

export default LockedPathbooks;
