import React from "react";
import propTypes from "prop-types";
import { Row, Typography } from "antd";
import { ArrowLeftOutlined } from "@ant-design/icons";
const Header = ({ title, goBack, hideArrow }) => {
	return (
		<Row>
			<div onClick={() => (goBack ? goBack() : null)} style={{ cursor: goBack ? "pointer" : "default" }}>
				<Typography.Title level={2}>
					{!hideArrow && <ArrowLeftOutlined style={{ fontSize: 22, marginRight: 10 }} />}
					{title}
				</Typography.Title>
			</div>
		</Row>
	);
};

Header.propTypes = {
	goBack: propTypes.func,
	hideArrow: propTypes.bool,
	title: propTypes.string,
};

export default Header;
