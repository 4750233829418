function Settings(
	state = {
		lang: "es",
	},
	action
) {
	switch (action.type) {
		case "SET_LANGUAGE":
			return {
				...state,
				lang: action.payload,
			};
		default:
			return state;
	}
}

export default Settings;
