import React from "react";
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";
import { useSelector } from "react-redux";
import "./App.less";
import "./translations";

import Login from "./pages/login";
import Home from "./pages/home";
import Stats from "./pages/stats";
import Config from "./pages/config";
import Teachers from "./pages/teachers";
import Students from "./pages/students";
import StudentsStats from "./pages/students/stats/index";
import LockedPathbooks from "./pages/lockedPathbooks";
import ReadingComprehension from "./pages/students/stats/comprehension";
import Page from "./components/page";
const ProtectedRoute = ({ component: Component, ...rest }: any) => {
	const auth = useSelector(state => state.user);
	return (
		<Route
			{...rest}
			render={props => {
				if (auth) {
					return <Component {...props} />;
				} else {
					return <Redirect to={"/login"} />;
				}
			}}
		/>
	);
};

const App = () => {
	return (
		<BrowserRouter>
			<Switch>
				<Route component={Login} path={"/login"} />
				<Page>
					<ProtectedRoute component={Home} exact path="/" />
					<ProtectedRoute component={Teachers} exact path="/maestros" />
					<ProtectedRoute component={Students} exact path="/estudiantes" />
					<ProtectedRoute component={StudentsStats} exact path="/estudiantes/:id/estadisticas" />
					<ProtectedRoute component={ReadingComprehension} exact path="/estudiantes/:id/evaluacion-lectora" />
					<ProtectedRoute component={Stats} exact path="/estadisticas" />
					<ProtectedRoute component={Config} exact path="/configuracion" />
					<ProtectedRoute component={LockedPathbooks} exact path="/control-pathbooks" />
				</Page>
			</Switch>
		</BrowserRouter>
	);
};

export default App;
