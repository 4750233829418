module.exports = {
	primary: "#E98B00",
	stat1: "#4466C7",
	stat2: "#47A5FC",
	stat3: "#169E94",
	stat4: "#8DC73F",
	stat5: "#FAA306",
	stat6: "#EB3A4E",
	stat7: "#f4c426",
	stat8: "#9c64bc",
	stat9: "#FF6766",
	stat10: "#B37358",
	stat11: "#216bae",
};
