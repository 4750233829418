import React from "react";
import styled from "styled-components";
import propTypes from "prop-types";
import { Typography, Modal, Col, Row, Button } from "antd";
import { LockOutlined, UnlockOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import "../translations/index";

import colors from "../styles/colors";

const CoverImage = styled.img`
	object-fit: cover;
	border-radius: 6px;
	width: 100%;
	height: 100%;
`;

const Title = styled(Typography.Text)`
	text-align: left;
	text-overflow: ellipsis;
	display: -webkit-box;
	overflow: hidden;
	-webkit-line-clamp: ${props => props.lines};
	-webkit-box-orient: vertical;
`;

const Text = styled(Typography.Text)`
	text-align: left;
	text-overflow: ellipsis;
	display: -webkit-box;
	overflow: hidden;
	-webkit-line-clamp: 7;
	-webkit-box-orient: vertical;
	font-size: 0.9em;
	color: #222;
	line-height: 1.8em;
	@media (max-width: 700) {
		-webkit-line-clamp: 5;
	}
`;

const Pathbook = props => {
	const lockedPathbook = () => {
		Modal.confirm({
			title: props.lock ? t("pathbook.lockPathbook") : t("pathbook.unlockPathbook"),
			content: props.lock ? t("pathbook.lockText") : t("pathbook.unlockTex"),
			okText: t("pathbook.accept"),
			cancelText: t("pathbook.cancel"),
			onOk: () => {
				props.toggleLockedPathbook();
			},
		});
	};
	const { t } = useTranslation();
	const goPathbook = () => window.open(`https://web.pathbooks.app/pathbook/${props.id}`);
	const buttonColor = () => {
		if (!props.isAdmin) return props.lock ? "#ff9090" : "#eafed2";
		return props.lock ? "#E50000" : "#68C100";
	};
	return (
		<Row>
			<Col span={12}>
				<div style={{ overflow: "hidden", maxHeight: 300, minHeight: 180, minWidth: 120, maxWidth: 200, margin: "auto" }}>
					<CoverImage alt={props.title} src={props.cover} width={240} />
				</div>
			</Col>
			<Col span={12} style={{ paddingLeft: "4px" }}>
				<Title lines={1} style={{ fontWeight: "bold", marginTop: "3px" }}>
					{props.title}
				</Title>

				<Title lines={1} style={{ color: "#056FA7", marginBottom: 7, WebkitLineClamp: 1 }}>
					{props.author}
				</Title>

				<div>
					<Text>{props.summary}</Text>
					<span onClick={goPathbook} style={{ color: colors.primary, textDecoration: "underline", padding: 5, marginTop: 1, cursor: "pointer" }}>
						{t("pathbook.readPathook")}{" "}
					</span>
				</div>
				<Button
					block
					disabled={!props.isAdmin}
					icon={props.lock ? <LockOutlined /> : <UnlockOutlined />}
					onClick={lockedPathbook}
					style={{ background: buttonColor(), color: "white", position: "absolute", bottom: 0 }}>
					{props.lock ? t("pathbook.lock") : t("pathbook.unlock")}
				</Button>
			</Col>
		</Row>
	);
};

Pathbook.propTypes = {
	author: propTypes.string.isRequired,
	cover: propTypes.string.isRequired,
	id: propTypes.string,
	isAdmin: propTypes.bool,
	lock: propTypes.bool,
	summary: propTypes.string.isRequired,
	title: propTypes.string.isRequired,
	toggleLockedPathbook: propTypes.func,
};

export default Pathbook;
