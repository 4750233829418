import React, { useState, useEffect } from "react";
import { Row, Col, Typography, Spin, Button, Card, Rate } from "antd";
import { useHistory, useParams, Link } from "react-router-dom";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { BarChartOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import SmartTable from "@offset-dev/antd-smart-table";
import moment from "moment";

import "../../../translations/index";
import { Title } from "../../../components/useChart";
import EmptyQuiz from "../../../assets/emptyQuiz.png";
import { firestore } from "../../../firebase";
import colors from "../../../styles/colors";

import QuizDetails from "../../../components/quizDetails";
import Header from "../../../components/header";

const EmptyImage = styled.div`
	background-image: ${props => `url(${props.image})`};
	background-repeat: no-repeat;
	background-size: contain;
	background-position: center;
	height: 400px;
	width: 100%;
	opacity: 0.8;

	@media (max-width: 750px) {
		height: 200px;
	}
`;

const Comprehension = () => {
	const { id: studentID } = useParams();
	const [student, setStudent] = useState({});
	const history = useHistory();
	const school = useSelector(state => state.school);
	const [loading, setLoading] = useState(true);
	const [state, setState] = useState({});
	const [showQuiz, setShowQuiz] = useState([]);
	const [viewDetails, setViewDetails] = useState(null);
	const [score, setScore] = useState(0);
	const { t } = useTranslation();

	useEffect(() => {
		const r = async () => {
			const query = await firestore().doc(`schools/${school.id}/students/${studentID}`).get();
			query.exists ? setStudent(query.data()) : history.goBack();
		};
		school && studentID ? r() : history.goBack();
	}, [history, school, studentID]);

	useEffect(() => {
		// setLoading(false);
		const r = async () => {
			const query = await firestore().collection(`users/${studentID}/quiz`).get();
			const data = query.docs.map(doc => ({ ...doc.data(), id: doc.id }));
			const allQuiz = data.reduce((ac, el) => {
				if (ac[el.pathbookTitle[el.langCode]]) {
					ac[el.pathbookTitle[el.langCode]].push(el);
					return ac;
				}
				ac[el.pathbookTitle[el.langCode]] = [el];
				return ac;
			}, {});
			const betterQuiz = Object.keys(allQuiz).map((key, index) => {
				const better = allQuiz[key].reduce((prev, current) => (prev.score > current.score ? prev : current));
				return better;
			});
			setShowQuiz(betterQuiz);
			setState({ total: data.length, quiz: allQuiz });
			setLoading(false);
			const summation = betterQuiz.reduce((ac, el) => ac + el.score, 0);
			setScore((summation / betterQuiz.length) * 0.05);
		};
		r();
	}, [studentID]);

	if (viewDetails)
		return <QuizDetails allQuiz={state.quiz[viewDetails.pathbookTitle[viewDetails.langCode]]} quiz={viewDetails} setViewDetails={setViewDetails} />;

	return (
		<>
			<Row align="middle" gutter={[15, 15]} justify="space-between">
				<Col>
					<Header goBack={() => history.goBack()} title={t("studentsComprehension.evualation")} />
				</Col>

				<Col>
					<Row style={{ display: "flex" }}>
						<Typography.Title level={3} style={{ marginRight: 10 }}>
							Ir a:
						</Typography.Title>
						<Link to={`/estudiantes/${studentID}/estadisticas`}>
							<Button icon={<BarChartOutlined style={{ position: "relative", top: 2 }} />} type={"primary"}>
								{t("studentsComprehension.stats")}
							</Button>
						</Link>
					</Row>
				</Col>
			</Row>
			<Row align={"middle"} gutter={[15, 15]} justify={"space-between"}>
				<Col style={{ marginTop: 20, marginBottom: 20 }}>
					<Typography.Paragraph>
						<b>{student?.name}</b> <br />
						{student?.group && (
							<>
								<b>{t("studentsStats.grade")}</b>
								{`${student?.group}  `}
							</>
						)}
						{student?.grade && (
							<>
								<b>{t("studentsStats.group")}</b>
								{`${student?.grade}  `}
							</>
						)}
						<br />
					</Typography.Paragraph>
				</Col>
			</Row>
			{loading ? (
				<Col span={24}>
					<div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
						<Spin size="large" />
						<Title>
							{t("studentsComprehension.loadingInformation")}
							{student?.name ? student?.name.split(" ")[student?.name.split(" ").length - 1] : t("studentsComprehension.theStudent")}
						</Title>
					</div>
				</Col>
			) : (
				<Row gutter={[20, 20]}>
					{!!showQuiz.length && (
						<>
							<Col md={score === 0 ? 12 : 8} xs={24}>
								<Card style={{ backgroundColor: colors.stat1 }}>
									<Spin spinning={loading} style={{ backgroundColor: colors.stat1 }}>
										<Typography.Title level={3} style={{ color: "#fff" }}>
											{t("studentsComprehension.quizTotal")}
										</Typography.Title>
										<Typography.Title style={{ margin: 0, fontSize: 25, color: "#fff" }}>{showQuiz.length}</Typography.Title>
									</Spin>
								</Card>
							</Col>
							<Col md={score === 0 ? 12 : 8} xs={24}>
								<Card style={{ backgroundColor: colors.stat2 }}>
									<Spin spinning={loading} style={{ backgroundColor: colors.stat2 }}>
										<Typography.Title level={3} style={{ color: "#fff" }}>
											{t("studentsComprehension.attemptsTotal")}
										</Typography.Title>
										<Typography.Title style={{ margin: 0, fontSize: 25, color: "#fff" }}>{state?.total}</Typography.Title>
									</Spin>
								</Card>
							</Col>
							{score !== 0 && (
								<Col md={8} xs={24}>
									<Card style={{ backgroundColor: colors.stat3 }}>
										<Spin spinning={loading} style={{ backgroundColor: colors.stat3 }}>
											<Typography.Title level={3} style={{ color: "#fff" }}>
												{t("studentsComprehension.readingComprehension")}
											</Typography.Title>
											<Row justify="center">
												<Rate disabled style={{ fontSize: 20 }} value={score} />
											</Row>
										</Spin>
									</Card>
								</Col>
							)}
						</>
					)}

					{!!showQuiz.length ? (
						<div style={{ display: "flex", justifyContent: "center", width: "100%" }}>
							<SmartTable
								columns={[
									{
										title: t("teachersText"),
										width: 400,
										render: quiz => (
											<Typography.Text
												onClick={() => setViewDetails(quiz)}
												style={{ color: colors.primary, fontWeight: "bold", cursor: "pointer" }}>
												{quiz.pathbookTitle[quiz.langCode]}
											</Typography.Text>
										),
									},

									{
										title: t("studentsComprehension.attemps"),

										render: quiz => (
											<Typography.Text>
												<span> {state.quiz[quiz.pathbookTitle[quiz.langCode]].length}</span>
											</Typography.Text>
										),
										width: 100,
									},
									{
										title: t("studentsComprehension.qualification"),
										render: quiz => (
											<>
												<Typography.Text>
													<span> {quiz.score}</span>
												</Typography.Text>
											</>
										),
										width: 130,
									},
									{
										title: t("studentsComprehension.date"),
										render: quiz => (
											<>
												<Typography.Text>
													<span>{moment(quiz.createdAt?.toDate()).format("ll")}</span>
												</Typography.Text>
											</>
										),
										width: 200,
									},
								]}
								dataSource={showQuiz}
								loading={loading}
								rowKey={"id"}
								scroll={{ x: 400 }}
								spanish
								style={{ width: "100%" }}
							/>
						</div>
					) : (
						<div style={{ height: "60vh", justifyContent: "center", width: "100%", alignItems: "center", display: "flex" }}>
							<Col span={24} style={{ alignItems: "center", justifyContent: "center", display: "flex", flexDirection: "column" }}>
								<EmptyImage image={EmptyQuiz} />
								<Title style={{ fontSize: 20, margin: "10px 0", textAlign: "center" }}>
									{t("studentsComprehension.notInformation")} {student?.name.split(" ")[student?.name.split(" ").length - 1]}.
								</Title>

								<Button icon={<BarChartOutlined />} onClick={() => history.replace("estadisticas")} type={"primary"}>
									{t("studentsComprehension.reviewStats")}
								</Button>
							</Col>
						</div>
					)}
				</Row>
			)}
		</>
	);
};

export default Comprehension;
