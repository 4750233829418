import { loadStats } from "../../utils/stats/load";

export const index = () => {
	return async dispatch => {
		dispatch({
			type: "FETCHING",
		});
		try {
			const s = await loadStats(null, [], []);
			dispatch({
				type: "SET_STAT",
				payload: {
					...s,
					academicPathbooks: s.topPathbooks.filter(x => x.academic),
					pathbooksAA: s.topPathbooks.filter(x => x.classification === "AA"),
					pathbooksB15: s.topPathbooks.filter(x => x.classification === "B15"),
					pathbooksB: s.topPathbooks.filter(x => x.classification === "B"),
					pathbooksA: s.topPathbooks.filter(x => x.classification === "A"),
					loading: false,
					fetching: true,
				},
			});
		} catch (error) {}
	};
};

export const clearStats = () => {
	return async dispatch => {
		await dispatch({ type: "CLEAR_STAT" });
	};
};
