/* eslint-disable max-lines */
/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { Col, Row, Button, Tag, Modal as RawModal, Input, Switch, Tooltip, Select } from "antd";
import { KeyOutlined, BarChartOutlined, EditOutlined, ReadOutlined, SearchOutlined } from "@ant-design/icons";
import SmartTable from "@offset-dev/antd-smart-table";
import { FileExcelOutlined } from "@ant-design/icons";
import { CSVLink } from "react-csv";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import lodash from "lodash";
import "../../translations/index";

import { firestore, functions } from "../../firebase";
import Modal from "./modal";
import { updateStudents, updateStudent } from "../../utils/students/update";
import disableStudents from "../../utils/students/disable";
import updatePassword from "../../utils/updatePassword";

import Header from "../../components/header";
import colors from "../../styles/colors";
import moment from "moment";

const StudentsPage = () => {
	const user = useSelector(state => state.user);
	const school = useSelector(state => state.school);
	const [modal, setModal] = React.useState(false);
	const [loading, setLoading] = React.useState(true);
	const [allStudents, setAllStudents] = React.useState([]);
	const [students, setStudents] = React.useState([]);
	const [selectedStudents, setSelectedStudents] = React.useState([]);
	const [allTeachers, setAllTeachers] = React.useState([]);
	const [showAll, setShowAll] = React.useState(false);
	// const [view, setView] = React.useState("table");
	const [searchFor, setSearchFor] = React.useState("name");
	const { t } = useTranslation();
	const load = () => {
		const r = async () => {
			setLoading(true);
			const teachersQuery = await firestore().collection(`schools/${school.id}/teachers`).orderBy("name", "asc").get();
			setAllTeachers(teachersQuery.docs.map(x => ({ id: x.id, ...x.data() })));

			let studentsQuery;
			if (user.role === "Teacher" && !showAll) {
				const teacherRef = firestore().doc(`schools/${school.id}/teachers/${user.uid}`);
				// Load students where `teacher` or `teachers` is set
				const studentsQuery1 = await firestore()
					.collection(`schools/${school.id}/students`)
					.where("teacher", "==", teacherRef)
					.orderBy("name", "asc")
					.get();
				const studentsQuery2 = await firestore()
					.collection(`schools/${school.id}/students`)
					.where("teachers", "array-contains", teacherRef)
					.orderBy("name", "asc")
					.get();
				const studentQueries = [...studentsQuery1.docs, ...studentsQuery2.docs];
				studentsQuery = {
					docs: lodash.uniq(studentQueries),
				};
			} else {
				studentsQuery = await firestore().collection(`schools/${school.id}/students`).orderBy("name", "asc").get();
			}
			const data = studentsQuery.docs.map(x => ({
				id: x.id,
				...x.data(),
				teacherName: teachersQuery.docs.find(y => x.data().teacher?.id === y.id)?.data()?.name,
				teachersName: x.data().teachers ? x.data().teachers.map(y => teachersQuery.docs.find(z => y.id === z.id)?.data()?.name) : [],
				timestamp: x.data().timestamp.toDate(),
			}));

			if (!data.length && !allStudents.length) setShowAll(true);
			setAllStudents(data);
			setStudents(data);

			setLoading(false);
		};
		if (school) r().catch();
	};

	const updateStudentsFunction = () => {
		return updateStudents(allTeachers, school.groups, selectedStudents).then(() => {
			setSelectedStudents([]);
			return load();
		});
	};

	const disableStudentsFunction = () => {
		return disableStudents(selectedStudents).then(() => {
			setSelectedStudents([]);
			return load();
		});
	};

	const toggle = (uid, activate) => {
		RawModal.confirm({
			title: t("students.pleaseConfirm"),
			content: (
				<span>
					{t("students.this")} {activate ? t("students.desactive") : t("students.active")} {t("students.user")} <br /> {t("students.confirmContinue")}
				</span>
			),
			okText: activate ? t("students.desactiveStudent") : t("students.activeStudent"),
			onOk: async () => {
				await functions().httpsCallable("school-toggleStudent")({
					student: uid,
				});
				return load();
			},
		});
	};

	const onSearch = e => {
		const data = allStudents.filter(x => x?.[searchFor]?.toLowerCase()?.includes(e.toLowerCase()));
		setStudents(data);
	};

	React.useEffect(() => {
		load();
	}, [school, user, showAll]);

	const exportedData = students.map(s => ({
		Nombre: s.name,
		Escuela: school.name,
		"Maestro(s)": s.teachersName,
		Grupo: s.group ? s.group : "No asignado",
		Grado: s.grade,
		Activo: s.active ? "Sí" : "No",
		Usuario: s.username,
		Contraseña: s.password ? s.password : "No registrada",
		"Fecha de registro": moment(s.timestamp).format("DD-MM-YYYY"),
	}));

	return (
		<>
			<Modal close={() => setModal(false)} reload={load} teachers={allTeachers} visible={modal} />
			<Row>
				<Header hideArrow title={t("studentsText")} />
				<Col flex={1} style={{ paddingTop: 6 }}>
					<Row gutter={12} justify={"end"}>
						{user.schoolAdmin ? (
							<>
								<Col>
									<Button disabled={!selectedStudents.length} onClick={updateStudentsFunction}>
										{t("students.modify")}
										{t("space")}
										{selectedStudents.length}
										{t("space")}
										{t("student")}
										{selectedStudents.length !== 1 ? "s" : null}
									</Button>
								</Col>
								<Col>
									<Button disabled={!selectedStudents.length} onClick={disableStudentsFunction}>
										{t("disable")} {t("space")}
										{selectedStudents.length}
										{t("space")}
										{t("student")}
										{selectedStudents.length !== 1 ? "s" : null}
									</Button>
								</Col>
								<Col>
									<CSVLink data={exportedData} filename={"Estudiantes.csv"}>
										<Button icon={<FileExcelOutlined />}>{t("students.exportStudents")}</Button>
									</CSVLink>
								</Col>
								<Col>
									<Button onClick={() => setModal(true)} type={"primary"}>
										{t("students.addStudent")}
									</Button>
								</Col>
							</>
						) : (
							<Col>
								<Switch checked={showAll} checkedChildren="TODOS" onChange={setShowAll} unCheckedChildren={t("students.myStudents")} />
							</Col>
						)}
					</Row>
				</Col>
			</Row>
			<Row style={{ margin: "10px 0" }}>
				<Input.Group>
					<Select onChange={e => setSearchFor(e)} style={{ width: 130, color: colors.primary }} value={searchFor}>
						<Select.Option value={"name"}>{t("name")}</Select.Option>
						<Select.Option value={"teacherName"}>{t("teacher")}</Select.Option>
						<Select.Option value={"username"}>{t("students.userName")}</Select.Option>
					</Select>
					<Input.Search
						allowClear
						enterButton={<SearchOutlined />}
						onChange={e => onSearch(e.target.value)}
						onSearch={onSearch}
						placeholder={t("students.searchStudent")}
						style={{ width: "calc(100% - 130px)" }}
					/>
				</Input.Group>
			</Row>
			{/* <Row justify="end" style={{ background: "#f8fafb", marginBottom: 10 }}>
				<Menu mode="horizontal" onClick={e => setView(e.key)} selectedKeys={view} style={{ background: "#f8fafb" }}>
					<Menu.Item icon={<GroupOutlined />} key={"cards"}>
						{t("students.grid")}
					</Menu.Item>
					<Menu.Item icon={<OrderedListOutlined />} key={"table"}>
						{t("students.table")}
					</Menu.Item>
				</Menu>
			</Row> */}

			<SmartTable
				columns={[
					{
						title: t("name"),
						defaultSortOrder: "ascend",
						sorter: (a, b) => a.name.localeCompare(b.name),
						search: "name",
						width: 350,
						render: x => (
							<>
								<Row>
									<Link to={`estudiantes/${x.id}/estadisticas`}>{x.name}</Link>
								</Row>
								<Row justify="space-between">
									<Link to={`estudiantes/${x.id}/estadisticas`}>
										<Button icon={<BarChartOutlined />}>{t("students.stats")}</Button>
									</Link>
									<Link to={`estudiantes/${x.id}/evaluacion-lectora`}>
										<Button icon={<ReadOutlined />}>{t("students.readingAssessment")}</Button>
									</Link>
								</Row>
							</>
						),
					},
					{
						title: t("students.userName"),
						width: 120,
						search: "username",
						dataIndex: "username",
						defaultSortOrder: "ascend",
						sorter: (a, b) => a.username.localeCompare(b.username),
					},
					{
						title: t("students.group"),
						filters: school?.groups?.map(x => ({
							value: x,
							text: x,
						})),
						onFilter: (value, record) => record.group === value,
						width: 160,
						render: x => {
							return (
								<Row gutter={[10, 10]} style={{ marginTop: 3 }}>
									<Col>
										{t("students.grade")}{" "}
										<span style={{ fontWeight: "bold" }}>
											{x.grade === "A" ? t("primary") : x.grade === "B" ? t("middleSchool") : x.grade === "B15" ? t("highSchool") : "-"}
										</span>
									</Col>
									<Col>
										{t("students.group")}: <span style={{ fontWeight: "bold" }}>{x.group} </span>
									</Col>
								</Row>
							);
						},
					},
					{
						title: t("teacher", { count: 10 }),
						dataIndex: "teachersName",
						render: (record, _) => {
							if (record.length) return record.map(x => <Tag>{x}</Tag>);
							return _.teacherName;
						},
						width: 180,
					},
					{
						title: t("students.status"),
						filters: [
							{ value: true, text: t("students.statusActive") },
							{ value: false, text: t("students.statusDesactive") },
						],
						onFilter: (value, record) => record.active === value,
						render: record => (
							<Tooltip placement="top" title={record.active ? t("students.desactiveUser") : t("students.activeUser")}>
								<Tag color={record.active ? "green" : "red"} onClick={() => toggle(record.id, record.active)} style={{ cursor: "pointer" }}>
									{record.active ? t("students.statusActive") : t("students.statusDesactive")}{" "}
								</Tag>
							</Tooltip>
						),
						width: 90,
					},
					{
						render: record => (
							<>
								<Tooltip placement="left" title={t("students.changePassword")}>
									<Button icon={<KeyOutlined />} onClick={() => updatePassword(record.id)} style={{ margin: 2 }} />
								</Tooltip>
								{user.schoolAdmin && (
									<Tooltip placement="left" title={t("students.editStudent")}>
										<Button
											icon={<EditOutlined />}
											onClick={() => {
												updateStudent(record).then(load).catch();
											}}
											style={{ margin: 2 }}
										/>
									</Tooltip>
								)}
							</>
						),
						width: 50,
					},
				]}
				dataSource={students}
				loading={loading}
				rowKey={"id"}
				rowSelection={{
					selectedRowKeys: selectedStudents,
					onChange: e => setSelectedStudents(e),
				}}
				scroll={{ x: 950 }}
				spanish
			/>
		</>
	);
};

export default StudentsPage;
