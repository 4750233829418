import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import es from "./es.json";
import en from "./en.json";

i18n.use(initReactI18next)
	.init({
		resources: {
			es: {
				translation: es,
			},
			en: {
				translation: en,
			},
		},
		lng: "es",
		interpolation: {
			escapeValue: false,
		},
		fallbackLng: "es",
	})
	.catch();

export default i18n;
