import { Menu } from "antd";
import { TeamOutlined, HomeOutlined, LineChartOutlined, BookOutlined, ExclamationCircleOutlined, EyeOutlined, UserSwitchOutlined } from "@ant-design/icons";
import { useHistory, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../redux/actions/user";
import { useTranslation } from "react-i18next";
import ".././translations/index";

const MenuComponent = () => {
	const history = useHistory();
	const location = useLocation();
	const dispatch = useDispatch();
	const user = useSelector(state => state.user);
	const { t } = useTranslation();

	const navigate = e => {
		if (e.key === "logout") {
			return dispatch(logout());
		} else if (e.key === "/help") {
			return window.open("https://maestros.pathbooks.app/");
		} else if (e.key === "/read") {
			return window.open("https://web.pathbooks.app/");
		}
		history.push(e.key);
	};
	return (
		<Menu mode="inline" onClick={navigate} selectedKeys={[`/${location.pathname.split("/")[1]}`]} theme={"dark"}>
			<Menu.Item icon={<HomeOutlined style={{ fontSize: 20 }} />} key="/" style={{ display: "flex", alignItems: "center", height: 60 }}>
				{t("menu.home")}
			</Menu.Item>
			{user?.schoolAdmin && (
				<Menu.Item icon={<UserSwitchOutlined style={{ fontSize: 20 }} />} key="/maestros" style={{ display: "flex", alignItems: "center", height: 60 }}>
					{t("menu.teachers")}
				</Menu.Item>
			)}
			<Menu.Item icon={<TeamOutlined style={{ fontSize: 20 }} />} key="/estudiantes" style={{ display: "flex", alignItems: "center", height: 60 }}>
				{t("menu.students")}
			</Menu.Item>
			<Menu.Item icon={<LineChartOutlined style={{ fontSize: 20 }} />} key="/estadisticas" style={{ display: "flex", alignItems: "center", height: 60 }}>
				{t("menu.stats")}
			</Menu.Item>
			<Menu.Item icon={<BookOutlined style={{ fontSize: 20 }} />} key="/control-pathbooks" style={{ display: "flex", alignItems: "center", height: 60 }}>
				{t("menu.library")}
			</Menu.Item>
			<Menu.Item icon={<ExclamationCircleOutlined style={{ fontSize: 20 }} />} key="/help" style={{ display: "flex", alignItems: "center", height: 60 }}>
				{t("menu.helpTeacher")}
			</Menu.Item>
			<Menu.Item icon={<EyeOutlined style={{ fontSize: 20 }} />} key="/read" style={{ display: "flex", alignItems: "center", height: 60 }}>
				{t("menu.read")}
			</Menu.Item>
		</Menu>
	);
};

export default MenuComponent;
