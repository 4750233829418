import * as React from "react";

const EmptyStats = props => {
	return (
		<svg id="prefix__Capa_1" viewBox="0 0 500 500" x={0} xmlSpace="preserve" {...props} xmlns="http://www.w3.org/2000/svg" y={0}>
			<style>{".prefix__st0{fill:#f4c040}.prefix__st1{fill:#414141}.prefix__st4{opacity:.9}.prefix__st5{fill:#fff}"}</style>
			<g id="prefix__XMLID_113_" transform="matrix(.59546 .26482 -.26482 .59546 38.457 -43.38)">
				<path
					className="prefix__st0"
					d="M324.2 642.3c-.2-.7 0-1.4.6-1.8 2.1-1.5 4.1-3 4.1-3s-.8-2.4-1.6-4.9c-.2-.7 0-1.4.6-1.8.6-.4 1.3-.4 1.9 0 2.1 1.5 4.1 3 4.1 3s2.1-1.5 4.1-3c.6-.4 1.3-.4 1.9 0 .6.4.8 1.1.6 1.8-.8 2.4-1.6 4.9-1.6 4.9s2.1 1.5 4.1 3c.6.4.8 1.1.6 1.8-.2.7-.8 1.1-1.5 1.1H337s-.8 2.4-1.6 4.9c-.2.7-.8 1.1-1.5 1.1s-1.3-.5-1.5-1.1c-.8-2.4-1.6-4.9-1.6-4.9h-5.1c-.7 0-1.3-.4-1.5-1.1z"
					id="prefix__XMLID_114_"
				/>
			</g>
			<g id="prefix__XMLID_43_" transform="rotate(23.976 414.476 276.39)">
				<path
					className="prefix__st0"
					d="M280.7 135.5c-.4.8-1.2 1.2-2 1-5.4-.8-12.4-2-12.4-2s-3.2 6.4-5.7 11.2c-.4.8-1.2 1.2-2 1-.8-.1-1.5-.8-1.6-1.6-.9-5.4-2-12.4-2-12.4s-7.1-1.1-12.4-2c-.8-.1-1.5-.8-1.6-1.6-.1-.8.3-1.7 1-2 4.8-2.5 11.2-5.7 11.2-5.7s-1.1-7.1-2-12.4c-.1-.8.3-1.7 1-2 .8-.4 1.7-.2 2.3.4l8.9 8.9s6.4-3.2 11.2-5.7c.8-.4 1.7-.2 2.3.4.6.6.7 1.5.4 2.3-2.5 4.8-5.7 11.2-5.7 11.2l8.9 8.9c.5.4.6 1.3.2 2.1z"
					id="prefix__XMLID_112_"
				/>
			</g>
			<g id="prefix__XMLID_41_" transform="matrix(.59546 .26482 -.26482 .59546 38.457 -43.38)">
				<path
					className="prefix__st0"
					d="M257 296.6c-.2-.7 0-1.4.6-1.8 2.1-1.5 4.1-3 4.1-3s-.8-2.4-1.6-4.9c-.2-.7 0-1.4.6-1.8.6-.4 1.3-.4 1.9 0 2.1 1.5 4.1 3 4.1 3s2.1-1.5 4.1-3c.6-.4 1.3-.4 1.9 0 .6.4.8 1.1.6 1.8-.8 2.4-1.6 4.9-1.6 4.9s2.1 1.5 4.1 3c.6.4.8 1.1.6 1.8-.2.7-.8 1.1-1.5 1.1h-5.1s-.8 2.4-1.6 4.9c-.2.7-.8 1.1-1.5 1.1s-1.3-.5-1.5-1.1c-.8-2.4-1.6-4.9-1.6-4.9h-5.1c-.6 0-1.3-.5-1.5-1.1z"
					id="prefix__XMLID_42_"
				/>
			</g>
			<path
				className="prefix__st1"
				d="M109.4 334.1c-8.4 9.9-21.6 31.1-25.6 49.1-2.1 9.5-1.7 18.1 2.7 24 4.4 5.9 12.9 9.4 27.4 8 29.7-2.8 55.8-24.7 78.3-42.6l1.3-1.1-2.1-2.7-1.4 1.2c-22 17.5-47.5 39.2-76.5 41.9-12.8 1.2-20.5-1.4-24.4-6.7-3.9-5.2-3.9-12.9-2.1-21.2 4-17.5 16.7-38.1 24.9-47.7l1.1-1.3-2.6-2.2-1 1.3z"
				id="prefix__XMLID_40_"
			/>
			<path
				className="prefix__st1"
				d="M320.3 120.8c17.9-10.8 37.4-18.6 58.4-18.1 31.8.7 60.1 23.3 50.6 57.9-10.2 37-47 66.9-73.3 92.9l-1.2 1.2 2.4 2.4 1.2-1.2c26.6-26.4 63.8-56.9 74.2-94.5 10.2-37.1-19.8-61.6-53.8-62.3-21.7-.4-41.9 7.6-60.4 18.8l1.9 2.9z"
				id="prefix__XMLID_39_"
			/>
			<path
				className="prefix__st1"
				d="M118.9 278.4c-21.7 20.7-42.6 43.1-53 71.6l-.4 1-2.1-.8.4-1c10.5-29 31.9-51.8 54-72.9l1.1 2.1z"
				id="prefix__XMLID_38_"
			/>
			<g id="prefix__XMLID_36_" transform="matrix(.59546 .26482 -.26482 .59546 38.457 -43.38)">
				<path
					className="prefix__st0"
					d="M724.2 306.7c.4 1.3 0 2.7-1.1 3.4-3.9 2.8-7.8 5.7-7.8 5.7l3 9.2c.4 1.3 0 2.7-1.1 3.4-1.1.8-2.5.8-3.6 0-3.9-2.8-7.8-5.7-7.8-5.7s-3.9 2.9-7.8 5.7c-1.1.8-2.5.8-3.6 0s-1.5-2.2-1.1-3.4l3-9.2s-3.9-2.9-7.8-5.7c-1.1-.8-1.5-2.2-1.1-3.5.4-1.3 1.6-2.1 2.9-2.1h9.7l3-9.2c.4-1.3 1.6-2.1 2.9-2.1 1.3 0 2.5.9 2.9 2.1l3 9.2h9.7c1.1.1 2.2.9 2.7 2.2z"
					id="prefix__XMLID_37_"
				/>
			</g>
			<path
				d="M386 393.9c-4.5-9-10.5-17-16.2-25.2-5-7.2-8.9-14.7-14.2-21.9-12.1-16.3-23.9-33.3-35.3-49.8-.8-1.1-1.6-2.1-2.6-3 1.9 1.7 21.2-30.3 22.6-33.4 5.5-12.1 9.1-25 10.6-38.2 1.5-13.5.8-27.3-2.2-40.5-1.5-6.6-3.9-12.7-6.2-19-2.4-6.8-3.5-13.8-8.3-19.6-16.3-19.8-33.1-37.3-57.1-47.7-23.1-10-49.1-13-73.8-8-22.5 4.5-39.5 15.2-57.1 29.2-1.6 1.3-3.3 2.6-4.9 3.9-19.1 15.3-31.1 42.8-35.3 66.5-4.5 25.2-1 51.5 10 74.5 5.9 12.5 12.2 26.8 24 34.9 11.2 7.7 20.5 16.9 33 23.1 24.1 11.9 51.8 15.7 78.2 10.6 8.3-1.6 16.5-4 24.3-7.3.5 1.2 1.1 2.4 1.9 3.5 3.3 1.8 7.7 10.8 9.9 14 3.7 5.5 6.8 11.5 10.3 17.2 8.8 14 19.5 26.4 28.7 39.9 4.2 6.2 8.5 12.3 12.7 18.5 1.2 1.7 2.5 3.5 3.6 5.3 4.2 6.8 10.4 10.8 17.4 14.7 2.3 1.3 4.7 2.5 7.4 2.6 2.4.1 4.8-.7 7-1.5 5.3-1.9 10.8-4.3 14.3-8.7 3.8-4.9 4.5-11.5 3.3-17.6-1.2-6-3.3-11.7-6-17zM284.1 290.5c-46.2 31.9-109.5 20.2-141.4-26-31.9-46.2-20.2-109.5 26-141.4 46.2-31.9 109.5-20.2 141.4 26s20.3 109.5-26 141.4z"
				fill="#d78128"
			/>
			<circle cx={226.5} cy={206.8} fill="#888" opacity={0.18} r={101.7} />
			<g id="prefix__XMLID_27_">
				<g className="prefix__st4" id="prefix__XMLID_32_">
					<path
						className="prefix__st5"
						d="M233.2 280.1c-1.2.5-2.3.9-3.5 1.3-.6.2-1.2.3-1.7.5-.4.1-.7.1-1.1.2-1.2.2-2.5.3-3.7.4h-3.3c-1.1.1-2.4-.3-3.4-.5-1.4-.2-2.8-.5-4.2-.8-.6-.1-1.2-.3-1.9-.5-.3-.1-1.5-.6-1.8-.5-.2-.1-.4-.2-.7-.2-.7-.2-1.4-.5-2-.7-2.4-.9-4.8-2-7.1-3.2-1-.5-2.1-1.1-3.1-1.7l-1.5-.9c-.1-.1-.3-.2-.5-.2.1 0 .1.1.3.1 1 .4-.3-.2-.6-.4-4.1-2.7-7.9-5.8-11.5-9.3l-2.5-2.5-.4-.4c-.4-.5-.9-1-1.3-1.5-1.6-1.9-3.2-3.9-4.6-5.9-1.4-2-2.7-4-4-6.1-.6-1.1-1.3-2.2-1.8-3.3-.1-.1-.1-.3-.2-.4v0c-.1-.1-.1-.2-.2-.4s-.1-.3-.1-.4c-.2-.3-.4-.7-.5-1-2.1-4.5-3.9-9.1-5.2-13.8-.3-1.1-.6-2.3-.9-3.4-.1-.5-.2-1.1-.4-1.6-.1-.3-.1-.7-.2-1-.4-2.5-.8-4.9-1-7.4-.2-2.8-.4-5.6-.3-8.4 0-.7 0-1.3.1-2 0-.3.1-1.2 0-.2s0-.2 0-.5c.1-1.5.3-2.9.5-4.4.4-2.9.9-5.8 1.7-8.6v-.2c.1-.2.2-.8.3-.9.3-.8.6-1.6 1-2.3.1-.2.4-1 .7-1.6 0 0 .1-.2.3-.4l.9-1.2c2.2-2.7 2.1-7.2-.8-9.4-2.8-2.2-7.1-2.1-9.4.8-4.3 5.4-6.3 12.1-7.5 18.8-.9 5.5-1.3 11.2-1.1 16.9.3 10.9 2.8 21.9 7.1 31.9 8.7 20.5 24.6 38.2 45.1 47.4 12.5 5.6 27.6 8.3 40.6 2.8 3.2-1.4 5.5-5 4.1-8.5-1.4-3.5-5.2-5.9-8.7-4.5zm-70.3-95.4s-.3.5-.4.6c.1-.3.3-.6.4-.6z"
						id="prefix__XMLID_33_"
					/>
				</g>
				<g id="prefix__XMLID_30_">
					<path d="M167 243.4c-.1-.6-.3-.9-.3-.8.1.3.2.6.3.8z" fill="#336186" id="prefix__XMLID_31_" />
				</g>
			</g>
			<g className="prefix__st4" id="prefix__XMLID_25_">
				<path
					className="prefix__st5"
					d="M258.3 279.2c-2.2-2.9-6.2-3.8-9.3-1.7-2.9 2-3.9 6.4-1.7 9.3 2.2 2.9 6.2 3.8 9.3 1.7 2.9-2 3.9-6.4 1.7-9.3z"
					id="prefix__XMLID_26_"
				/>
			</g>
			<path
				d="M344.1 330c-.3-.1-.5-.3-.8-.3-6.1-1.7-14.6 9.5-19 12.6-7.8 5.4-15.7 10.7-23.7 15.9-3.8 2.5-2 3.8.1 6.7 1.6 2.2 3.1 4.4 4.7 6.6 4.6 6.4 9.2 12.7 13.7 19.1 10.5 14.5 20.9 29 31.4 43.6 5.3 7.3 10.1 16.1 16.3 22.7 4.6 4.9 12.6 6.6 19.4 6.2 13.1-.9 27-15.1 24.7-28.8-.8-4.8-3.3-9.1-5.8-13.2-13-21.7-26.8-43-41.4-63.7l-10.8-15c-1.9-2.8-5.6-10.5-8.8-12.4z"
				fill="#9a4e23"
			/>
		</svg>
	);
};

export default EmptyStats;
