import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/functions";
import "firebase/storage";

const firebaseConfig = {
	apiKey: "AIzaSyDbTofY5yTs--OSGBw7pxW1Db6Ra9o0d9U",
	authDomain: "pathbook.firebaseapp.com",
	databaseURL: "https://pathbook.firebaseio.com",
	projectId: "pathbook",
	storageBucket: "pathbook.appspot.com",
	messagingSenderId: "407045997235",
	appId: "1:407045997235:web:00135256eabf14dfb166c6",
	measurementId: "G-6DY9VRTTRX",
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);

// Create & Export
const auth = firebase.auth;
const firestore = firebase.firestore;
const functions = firebase.functions;
const storage = firebase.storage;

if (window.location.hostname === "localhost" && process.env.REACT_APP_DEV) {
	// auth().useEmulator("http://localhost:9099/");
	functions().useEmulator("localhost", 5001);
	// firestore().settings({
	// 	host: "localhost:8080",
	// 	ssl: false,
	// });
}

export { auth, firestore, functions, storage };
