import _ from "lodash";
import async from "async";
import axios from "axios";
import { firestore, functions } from "../../firebase";
import moment from "moment";

export const loadStats = async (range: Array, groups: Array, teachers: Array) => {
	const schoolData = await functions().httpsCallable("school-getStudents")({});
	const studentsArray = schoolData.data.students.map(x => x.id);
	const students = schoolData.data.students.filter(x => x.active);

	const allQueryStudents = !groups.length && !teachers.length ? students : [];

	// Let's check if students belongs in group
	const studentsInGroup = students.filter(x => {
		if (!groups.length) return false;
		return groups.includes(x.group);
	});

	// Let's check if students belongs to teacher
	const studentsInTeacher = students.filter(x => {
		if (!teachers.length) return false;
		return teachers.includes(x.teacher);
	});

	_.merge(allQueryStudents, studentsInGroup, studentsInTeacher);

	// If no users
	if (!allQueryStudents.length) {
		return {
			loading: false,
			reads: 0,
			interactions: 0,

			pathbooksRead: [],
			studentsReading: [],
			ratio: 0,
			topPathbooks: [],
			bestStudents: [],
			worstStudents: [],
			range: [],
		};
	}

	const usersIDs = allQueryStudents.map(x => `"${x.id}"`);
	const rangeQuery = range ? ` AND (timestamp BETWEEN ${range[0].startOf("day").valueOf()} AND ${range[1].endOf("day").valueOf()})` : "";
	const events = await axios("https://us-central1-pathbook.cloudfunctions.net/apiv2/analytics", {
		method: "POST",
		data: {
			query: `WHERE user IN (${usersIDs})` + rangeQuery,
		},
	});
	const schoolEvents = events.data.filter(x => studentsArray.includes(x.user));
	const dates = schoolEvents.map(x => moment(x.timestamp));
	const minDate = moment.min(dates);
	const maxDate = moment.max(dates);
	// Get Stats
	const finishedPathbook = schoolEvents.filter(x => x.event === "finishedPathbook");
	const pathbooksRead = [...new Set(finishedPathbook.map(x => x["pathbook"]))];
	const studentsReading = [...new Set(finishedPathbook.map(x => x.user))];

	// Get Top Pathbooks
	const countingPathbooks = _.chain(finishedPathbook).countBy("pathbook").toPairs().sortBy(1).reverse();
	const topPathbooks = await async.mapSeries(
		countingPathbooks.value(),
		async.asyncify(async x => {
			const pathbookData = await firestore().doc(`pathbooks/${x[0]}`).get();
			return {
				id: x[0],
				count: x[1],
				classification: pathbookData?.data()?.classification,
				name: pathbookData?.data()?.title?.["es"] ?? pathbookData?.data()?.title?.["en"],
				academic: pathbookData?.data()?.author?.id === "CSDa5AEH2m8bW8XsQPat",
				cover: pathbookData?.data()?.cover["es"] ?? pathbookData?.data()?.cover["en"],
			};
		})
	);

	// Get Top Students
	const countingStudents = _.chain(finishedPathbook).countBy("user").toPairs().sortBy(1).reverse();
	const allStudents = countingStudents.value().map(x => ({
		id: x[0],
		count: x[1],
		name: students.find(y => y.id === x[0]).name,
	}));
	studentsArray.forEach(x => {
		if (!studentsReading.includes(x)) {
			allStudents.push({
				id: x,
				count: 0,
				name: students.find(y => y.id === x)?.name ?? "",
			});
		}
	});

	const bestStudents = [...allStudents];
	bestStudents.sort((a, b) => b.count - a.count);
	const worstStudents = [...allStudents];
	worstStudents.sort((a, b) => a.count - b.count);

	const ratio = pathbooksRead.length / studentsReading.length;
	return {
		loading: false,
		reads: schoolEvents.filter(x => x.event === "finishedPathbook").length,
		interactions: schoolEvents.filter(x => x.event === "changedPage").length,

		pathbooksRead,
		studentsReading,
		ratio: ratio ? ratio.toFixed(2) : 0,
		topPathbooks,
		bestStudents,
		worstStudents,
		range: [minDate, maxDate],
	};
};
