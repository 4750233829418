/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable max-lines */
import React, { useState, useEffect } from "react";
import { Card, Col, Row, Spin, Typography, DatePicker, Switch, Button } from "antd";
import { useParams, Link } from "react-router-dom";
import { useSelector } from "react-redux";
import moment from "moment";
import { useHistory } from "react-router-dom";
import { ReadOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import "../../../translations/index";

import { CircularGraph, InteractionGraph, Title, Description } from "../../../components/useChart";
import ReadingTimeChart from "../../../components/readingTimeChart";
import UseTable from "../../../components/useTable";
import { firestore } from "../../../firebase";
import { loadStudent } from "../../../utils/stats/loadStudent";
import { renderReadingTime } from "../../../utils/readingTime";
import colors from "../../../styles/colors";
import Header from "../../../components/header";

const Stats = () => {
	const { id: student } = useParams();
	const history = useHistory();
	const school = useSelector(state => state.school);
	const [range, setRange] = useState(null);
	const [studentData, setStudentData] = useState(null);
	const [showCharts, setShowCharts] = useState(false);
	const { t } = useTranslation();
	const [state, setState] = useState({
		loading: true,
		reads: 0,
		interactions: 0,
		ratio: 0,
		lastReadingDate: null,

		pathbooksRead: [],
		studentsReading: [],
		topPathbooks: [],
		esPathbooks: [],
		enPathbooks: [],
		activeDays: [],
	});
	const [pathbookEvents, setpathbookEvents] = useState([]);
	const [totalReadingTime, setTotalReadingTime] = useState(null);
	const [firstRange, setFirstRange] = useState([]);
	const [readingTime, setReadingTime] = useState({});
	const load = () => {
		const run = async () => {
			setState(s => ({ ...s, loading: true }));
			const data = await loadStudent(student, range);
			setState(data.state);
			setpathbookEvents(data.pathbookEvents);
			setFirstRange(data.range);
			setReadingTime(data.readingTime);
		};

		run().catch();
	};
	const calculateReadingTime = () => {
		// calculate  Pathbook reading time
		const x = state.topPathbooks.map(x => ({ ...pathbookEvents[x.id] }));
		const topPathbooks = x.map((pathbook, index) => {
			const readingTimes = Object.keys(pathbook).map(day => {
				const events = x[index][day].map(e => ({ date: moment(e.date.value), event: e.event }));
				let pause = 0;
				for (const [index, value] of events.entries()) {
					if (value.event === "stoppedPathbook") {
						if (index + 1 < events.length) pause += value.date.diff(events[index + 1].date, "second");
					}
				}

				const dates = events.map(e => e.date);
				const diff = moment.max(dates).diff(moment.min(dates), "second");
				return diff + pause;
			});
			let readingTime = 0;
			for (const time of readingTimes) readingTime += time;
			const minutes = Math.trunc(readingTime / 60);
			const seconds = Math.trunc(readingTime % 60) * 0.01;
			return {
				...state.topPathbooks[index],
				readingTime: (minutes + seconds).toFixed(2),
			};
		});
		setState(s => ({ ...s, topPathbooks }));
		const total = topPathbooks.reduce((ac, el) => ac + Number(el.readingTime), 0);
		setTotalReadingTime(total.toFixed(2));
	};

	useEffect(calculateReadingTime, [pathbookEvents]);

	useEffect(() => {
		const r = async () => {
			const query = await firestore().doc(`schools/${school.id}/students/${student}`).get();
			query.exists ? setStudentData(query.data()) : history.goBack();
		};
		school && student ? r() : history.goBack();
	}, [history, school, student]);

	useEffect(load, [range, student]);

	return (
		<>
			<Row align={"middle"} gutter={[15, 15]} justify={"space-between"}>
				<Col>
					<Header goBack={() => history.replace("/estudiantes")} title={t("studentsStats.studentsStatics")} />
				</Col>
				<Col>
					<Row style={{ display: "flex" }}>
						<Typography.Title level={3} style={{ marginRight: 10 }}>
							{t("studentsStats.goTo")}
						</Typography.Title>
						<Link to={`/estudiantes/${student}/evaluacion-lectora`}>
							<Button icon={<ReadOutlined style={{ position: "relative", top: 2 }} />} type={"primary"}>
								{t("studentsStats.readingAssessment")}
							</Button>
						</Link>
					</Row>
				</Col>
			</Row>
			<Row align={"middle"} gutter={[15, 15]} justify={"space-between"}>
				<Col>
					<Typography.Paragraph>
						<b>{studentData?.name}</b> <br />
						{studentData?.group && (
							<>
								<b>{t("studentsStats.grade")}</b>
								{`${studentData?.group}  `}
							</>
						)}
						{studentData?.grade && (
							<>
								<b>{t("studentsStats.group")}</b>
								{`${studentData?.grade}  `}
							</>
						)}
						<br />
						{moment(studentData?.timestamp.toDate()).format("ll")}
					</Typography.Paragraph>
				</Col>
				<Col>
					<div style={{ color: colors.primary, fontWeight: "bold", opacity: 0.8 }}>{t("studentsStats.selectDates")}</div>
					<DatePicker.RangePicker
						format={"ll"}
						onChange={setRange}
						placeholder={[t("studentsStats.dateStart"), t("studentsStats.dateEnd")]}
						ranges={{
							[t("today")]: [moment(), moment()],
							[t("sevenDays")]: [moment().subtract(7, "days"), moment()],
							[t("fifteenDays")]: [moment().subtract(15, "days"), moment()],
							[t("thirtyDays")]: [moment().subtract(30, "days"), moment()],
						}}
						style={{ width: 300, marginBottom: 10 }}
						value={range || firstRange}
					/>
				</Col>
			</Row>
			<Row gutter={[15, 15]}>
				<Col md={8} xs={24}>
					<Card style={{ backgroundColor: colors.stat1 }}>
						<Spin spinning={state.loading} style={{ backgroundColor: colors.stat1 }}>
							<Typography.Title level={3} style={{ color: "#fff" }}>
								{t("studentsStats.totalRead")}
							</Typography.Title>
							<Typography.Title style={{ margin: 0, fontSize: 25, color: "#fff" }}>{state.reads}</Typography.Title>
						</Spin>
					</Card>
				</Col>
				<Col md={8} xs={24}>
					<Card style={{ backgroundColor: colors.stat2 }}>
						<Spin spinning={state.loading} style={{ backgroundColor: colors.stat2 }}>
							<Typography.Title level={3} style={{ color: "#fff" }}>
								{t("studentsStats.interactions")}
							</Typography.Title>
							<Typography.Title style={{ margin: 0, fontSize: 25, color: "#fff" }}>{state.interactions}</Typography.Title>
						</Spin>
					</Card>
				</Col>

				<Col md={8} xs={24}>
					<Card style={{ backgroundColor: colors.stat3 }}>
						<Spin spinning={state.loading} style={{ backgroundColor: colors.stat3 }}>
							<Typography.Title level={3} style={{ color: "#fff" }}>
								{t("studentsStats.totalTime")}
							</Typography.Title>
							<Typography.Title style={{ margin: 0, fontSize: 25, color: "#fff" }}>{renderReadingTime(totalReadingTime)}</Typography.Title>
						</Spin>
					</Card>
				</Col>

				<Col md={8} xs={24}>
					<Card loading={state.loding} style={{ backgroundColor: colors.stat4 }}>
						<Spin spinning={state.loading} style={{ backgroundColor: colors.stat4 }}>
							<Typography.Title level={3} style={{ color: "#fff" }}>
								{t("studentsStats.totalBooks")}
							</Typography.Title>
							<Typography.Title style={{ margin: 0, fontSize: 22, color: "#fff" }}>
								{state.pathbooksRead.length} {state.pathbooksRead.length === 1 ? "Pathbook" : "Pathbooks"}
							</Typography.Title>
						</Spin>
					</Card>
				</Col>
				<Col md={8} xs={24}>
					<Card loading={state.loding} style={{ backgroundColor: colors.stat5 }}>
						<Spin spinning={state.loading} style={{ backgroundColor: colors.stat5 }}>
							<Typography.Title level={3} style={{ color: "#fff" }}>
								{t("studentsStats.averageRea")}
							</Typography.Title>
							<Typography.Title style={{ margin: 0, fontSize: 22, color: "#fff" }}>
								{state.pathbooksRead.length === 0 ? state.reads : (state.reads / state.pathbooksRead.length).toFixed(2)}
							</Typography.Title>
						</Spin>
					</Card>
				</Col>
				<Col md={8} xs={24}>
					<Card loading={state.loding} style={{ backgroundColor: colors.stat6 }}>
						<Spin spinning={state.loading} style={{ backgroundColor: colors.stat6, color: "#fff" }}>
							<Typography.Title level={3} style={{ color: "#fff" }}>
								{t("studentsStats.averrageBookDay")}
							</Typography.Title>
							<Typography.Title style={{ margin: 0, fontSize: 22, color: "#fff" }}>{state.ratio}</Typography.Title>
						</Spin>
					</Card>
				</Col>

				<Col span={24}>
					{!state.loading ? (
						<Row justify="space-between" style={{ margin: 10 }}>
							<Title>
								{!state.lastReadingDate
									? t("studentsStats.userNotRead")
									: t("studentsStats.lasDateRead", { value: state.lastReadingDate.format("ll") })}
							</Title>
							<div>
								{t("studentsStats.showInformation")}
								<Switch
									checked={showCharts}
									checkedChildren={t("studentsStats.graph")}
									defaultChecked
									onChange={setShowCharts}
									style={{ marginLeft: 15 }}
									unCheckedChildren={t("studentsStats.table")}
								/>
							</div>
						</Row>
					) : (
						<div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
							<Spin size="large" />
							<Title>
								{t("studentsStats.loadingInformation")} {studentData?.name ? studentData?.name.split(" ")?.[0] : t("studentsStats.theStudent")}
							</Title>
						</div>
					)}
				</Col>
				<Col span={24}>
					<Card loading={state.loading}>
						<div style={{ maxHeight: 500 }}>
							{showCharts ? (
								<CircularGraph
									data={state.topPathbooks.map(x => ({ ...x }))}
									description={t("studentsStats.moreOnce")}
									title={t("studentsStats.bookEnding") + `${state.pathbooksRead.length}`}
								/>
							) : (
								<>
									<Row>
										<Title>
											{t("studentsStats.bookEnding")} {state.pathbooksRead.length}
										</Title>
									</Row>
									<Row>
										<Description style={{ marginBottom: 10 }}>{t("studentsStats.moreOnce")}</Description>
									</Row>
									<UseTable data={state.topPathbooks.map((x, y) => ({ ...x, range: y + 1 }))} hideRange showLastReadingDate showReadingTime />
								</>
							)}
						</div>
					</Card>
				</Col>

				<Col span={24}>
					<Card loading={state.loading}>
						<ReadingTimeChart
							allPathbooks={state.topPathbooks.sort((a, b) => (a.count < b.count ? 1 : a.count > b.count ? -1 : 0))}
							data={pathbookEvents}
							readingTime={readingTime}
						/>
					</Card>
				</Col>
				<Col span={24}>
					<Card loading={state.loading}>
						<Row>
							<Title>{t("studentsStats.timePlataform")}</Title>
						</Row>
						<Row>
							<Description style={{ marginBottom: 10 }}>{t("studentsStats.studentActivity")}</Description>
						</Row>
						{showCharts ? (
							<>
								<InteractionGraph
									data={state.activeDays}
									description={t("studentsStats.studentLanguage")}
									title={`${studentData?.name.split(" ")[studentData.name.split(" ").length - 1]} t("studentsStats.readIdioms")`}
									type="languages"
								/>
							</>
						) : (
							<UseTable data={state.activeDays} hideRange interactions showReadingTime></UseTable>
						)}
					</Card>
				</Col>
				{showCharts ? (
					<Col span={24}>
						<Card loading={state.loading}>
							<CircularGraph
								data={[
									{
										name: "Español",
										count: state.esPathbooks.reduce((ac, el) => ac + el.count, 0),
									},
									{
										name: "Ingles",
										count: state.enPathbooks.reduce((ac, el) => ac + el.count, 0),
									},
								]}
								description={t("studentsStats.studentLanguage")}
								title={`${studentData?.name.split(" ")[studentData.name.split(" ").length - 1]} t("studentsStats.readIdioms") `}
								type="languages"
							/>
						</Card>
					</Col>
				) : (
					<>
						<Col md={12} xs={24}>
							<Card loading={state.loading}>
								<Title style={{ marginBottom: 10 }}>{t("studentsStats.finishedSpanish")}</Title>
								<div style={{ maxHeight: 500, overflow: "scroll" }}>
									<UseTable data={state.esPathbooks.map((x, y) => ({ ...x, range: y + 1 }))} hideRange />
								</div>
							</Card>
						</Col>
						<Col md={12} xs={24}>
							<Card loading={state.loading}>
								<Title style={{ marginBottom: 10 }}>{t("studentsStats.finishedEnglish")}</Title>
								<div style={{ maxHeight: 500, overflow: "scroll" }}>
									<UseTable data={state.enPathbooks.map((x, y) => ({ ...x, range: y + 1 }))} hideRange />
								</div>
							</Card>
						</Col>
					</>
				)}
			</Row>
		</>
	);
};

export default Stats;
