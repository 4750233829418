/* eslint-disable max-lines */
import React, { useState, useEffect } from "react";
import { Card, Col, Row, Spin, Typography, Select, DatePicker, Switch } from "antd";
import { useSelector } from "react-redux";
import moment from "moment";
import { isNull } from "lodash";
import { useTranslation } from "react-i18next";
import "../../translations/index";

import { firestore } from "../../firebase";
import { loadStats } from "../../utils/stats/load";
import UseTable from "../../components/useTable";
import { BarGraph, Title } from "../../components/useChart";
import colors from "../../styles/colors";
import Header from "../../components/header";

const Stats = () => {
	const school = useSelector(state => state.school);
	const stats = useSelector(state => state.stats);
	const [groups, setGroups] = useState([]);
	const [teachers, setTeachers] = useState([]);
	const [allTeachers, setAllTeachers] = useState([]);
	const [range, setRange] = useState(null);
	const { t } = useTranslation();
	const [state, setState] = useState({
		reads: 0,
		interactions: 0,
		ratio: 0,
		pathbooksRead: [],
		studentsReading: [],
		topPathbooks: [],
		bestStudents: [],
		worstStudents: [],
		pathbooksA: [],
		pathbooksB: [],
		pathbooksB15: [],
		pathbooksAA: [],
		academicPathbooks: [],
		range: [],
	});
	const [loading, setLoading] = useState(true);
	const [showCharts, setShowCharts] = useState(true);

	const load = () => {
		const run = async () => {
			const s = await loadStats(range, groups, teachers);
			setState({
				...s,
				academicPathbooks: s.topPathbooks.filter(x => x.academic),
				pathbooksAA: s.topPathbooks.filter(x => x.classification === "AA"),
				pathbooksB15: s.topPathbooks.filter(x => x.classification === "B15"),
				pathbooksB: s.topPathbooks.filter(x => x.classification === "B"),
				pathbooksA: s.topPathbooks.filter(x => x.classification === "A"),
			});
			setLoading(false);
		};
		run().catch();
	};

	useEffect(() => {
		if (isNull(range) && !groups.length && !teachers.length) {
			setState(stats);
			return setLoading(stats.loading ?? false);
		}
		setLoading(true);
		load();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [range, groups, teachers, stats]);

	useEffect(() => {
		if (!school) return false;
		firestore()
			.collection(`schools/${school.id}/teachers`)
			.orderBy("name", "asc")
			.get()
			.then(res => {
				return setAllTeachers(res.docs.map(x => ({ key: x.id, ...x.data() })));
			})
			.catch();
	}, [school]);

	return (
		<>
			<Row gutter={[15, 15]} justify={"space-between"}>
				<Col>
					<Header hideArrow title={t("stats.statsText")} />
				</Col>

				<Col>
					<div style={{ color: colors.primary, fontWeight: "bold", opacity: 0.8 }}>{t("stats.selectPeriod")}</div>
					<DatePicker.RangePicker
						format={"ll"}
						onChange={setRange}
						ranges={{
							[t("today")]: [moment(), moment()],
							[t("sevenDays")]: [moment().subtract(7, "days"), moment()],
							[t("fifteenDays")]: [moment().subtract(15, "days"), moment()],
							[t("thirtyDays")]: [moment().subtract(30, "days"), moment()],
						}}
						style={{ width: "100%" }}
						value={range || [moment(stats?.range?.[0]), moment(stats?.range?.[1])]}
					/>
				</Col>
			</Row>
			<Row gutter={[15, 15]} justify={"space-between"} style={{ marginBottom: 20 }}>
				<Col xl={12} xs={24}>
					{t("stats.groups")}
					<Select mode={"multiple"} onChange={setGroups} style={{ width: "100%" }}>
						{school?.groups.map(x => (
							<Select.Option key={x} value={x}>
								{x}
							</Select.Option>
						))}
					</Select>
				</Col>
				<Col xl={12} xs={24}>
					{t("stats.teachers")}
					<Select mode={"multiple"} onChange={setTeachers} style={{ width: "100%" }}>
						{allTeachers.map(x => (
							<Select.Option key={x.key} value={x.key}>
								{x.name}
							</Select.Option>
						))}
					</Select>
				</Col>
			</Row>
			<Row gutter={[15, 15]}>
				<Col lg={12} xs={24}>
					<Card style={{ backgroundColor: colors.stat1 }}>
						<Spin spinning={loading} style={{ backgroundColor: colors.stat1, color: "white" }}>
							<Typography.Title level={3} style={{ color: "#fff" }}>
								{t("stats.totalReadings")}
							</Typography.Title>
							<Typography.Title style={{ margin: 0, fontSize: 40, color: "white" }}>{state.reads}</Typography.Title>
						</Spin>
					</Card>
				</Col>
				<Col lg={12} xs={24}>
					<Card style={{ backgroundColor: colors.stat2 }}>
						<Spin spinning={loading} style={{ backgroundColor: colors.stat2 }}>
							<Typography.Title level={3} style={{ color: "#fff" }}>
								{t("stats.interactionsBooks")}
							</Typography.Title>
							<Typography.Title style={{ margin: 0, fontSize: 40, color: "#fff" }}>{state.interactions}</Typography.Title>
						</Spin>
					</Card>
				</Col>
				{/* <Col xl={8} xs={24}>
					<Card style={{ backgroundColor: colors.stat3 }}>
						<Spin spinning={loading} style={{ backgroundColor: colors.stat3 }}>
							<Typography.Title level={3} style={{ color: "#fff" }}>
								{t("stats.averageBooks")}
							</Typography.Title>
							<Typography.Title style={{ margin: 0, fontSize: 40, color: "#fff" }}>{state.ratio}</Typography.Title>
						</Spin>
					</Card>
				</Col> */}

				<Col span={24}>
					{!loading ? (
						<Row justify="end" style={{ margin: 10 }}>
							{t("stats.showInformationIn")}
							<Switch
								checked={showCharts}
								checkedChildren={t("stats.graphics")}
								defaultChecked
								onChange={setShowCharts}
								style={{ marginLeft: 15 }}
								unCheckedChildren={t("stats.table")}
							/>
						</Row>
					) : (
						<div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
							<Spin size="large" />
							<Title>{t("stats.loadingInformation")}</Title>
						</div>
					)}
				</Col>

				<Col xl={showCharts ? 24 : 12} xs={24}>
					<Card loading={loading} style={{ width: "100%" }}>
						{showCharts ? (
							<BarGraph data={state.bestStudents.map((x, y) => ({ ...x, range: y + 1 }))} label={t("stats.topStudents")} />
						) : (
							<>
								<Typography.Title level={4}>{t("stats.topStudents")}</Typography.Title>
								<div style={{ maxHeight: 400 }}>
									<UseTable data={state.bestStudents.map((x, y) => ({ ...x, range: y + 1 }))} type="student" />
								</div>
							</>
						)}
					</Card>
				</Col>
				<Col xl={showCharts ? 24 : 12} xs={24}>
					<Card loading={loading} style={{ width: "100%" }}>
						{showCharts ? (
							<>
								<BarGraph data={state.worstStudents.map((x, y) => ({ ...x, range: y + 1 }))} label={t("stats.topLeast")} showAll />
								<p style={{ fontWeight: "bold", textAlign: "center" }}>{t("stats.graphEmpty")}</p>
							</>
						) : (
							<>
								<Typography.Title level={4}>{t("stats.topLeast")}</Typography.Title>
								<div style={{ maxHeight: 400 }}>
									<UseTable data={state.worstStudents.map((x, y) => ({ ...x, range: y + 1 }))} type="student" />
								</div>
							</>
						)}
					</Card>
				</Col>
				<Col xl={showCharts ? 24 : 12} xs={24}>
					<Card loading={loading} style={{ width: "100%" }}>
						{showCharts ? (
							<BarGraph data={state.topPathbooks.map((x, y) => ({ ...x, range: y + 1 }))} label={t("stats.topBooksSchool")} type="pathbooks" />
						) : (
							<>
								<Typography.Title level={4}>{t("stats.topBooksSchool")}</Typography.Title>
								<div style={{ maxHeight: 400 }}>
									<UseTable data={state.topPathbooks.map((x, y) => ({ ...x }))} type="pathbook" />
								</div>
							</>
						)}
					</Card>
				</Col>
				{!!state.academicPathbooks.length && (
					<Col xl={showCharts ? 24 : 12} xs={24}>
						<Card loading={loading} style={{ width: "100%" }}>
							{showCharts ? (
								<BarGraph data={state.academicPathbooks.map((x, y) => ({ ...x }))} label={t("stats.topAcademicBooks")} type="pathbooks" />
							) : (
								<>
									<Typography.Title level={4}>{t("stats.topAcademicBooks")}</Typography.Title>
									<div style={{ maxHeight: 400 }}>
										<UseTable data={state.academicPathbooks.map((x, y) => ({ ...x, range: y + 1 }))} type="pathbook" />
									</div>
								</>
							)}
						</Card>
					</Col>
				)}
				{!!state.pathbooksAA.length && (
					<Col xl={showCharts ? 24 : 12} xs={24}>
						<Card loading={loading} style={{ width: "100%" }}>
							{showCharts ? (
								<BarGraph
									data={state.pathbooksAA.map((x, y) => ({ ...x, range: y + 1 }))}
									label={t("stats.topBooksPrimary")}
									type="pathbooks"
								/>
							) : (
								<>
									<Typography.Title level={4}>{t("stats.topBooksPrimary")}</Typography.Title>
									<div style={{ maxHeight: 400 }}>
										<UseTable data={state.pathbooksAA.map((x, y) => ({ ...x, range: y + 1 }))} type="pathbook" />
									</div>
								</>
							)}
						</Card>
					</Col>
				)}
				{!!state.pathbooksB.length && (
					<Col xl={showCharts ? 24 : 12} xs={24}>
						<Card loading={loading} style={{ width: "100%" }}>
							{showCharts ? (
								<BarGraph
									data={state.pathbooksB.map((x, y) => ({ ...x, range: y + 1 }))}
									label={t("stats.topBooksMiddleSchool")}
									type="pathbooks"
								/>
							) : (
								<>
									<Typography.Title level={4}>{t("stats.topBooksMiddleSchool")}</Typography.Title>
									<div style={{ maxHeight: 400 }}>
										<UseTable data={state.pathbooksB.map((x, y) => ({ ...x, range: y + 1 }))} type="pathbook" />
									</div>
								</>
							)}
						</Card>
					</Col>
				)}
				{!!state.pathbooksB15.length && (
					<Col xl={showCharts ? 24 : 12} xs={24}>
						<Card loading={loading} style={{ width: "100%" }}>
							{showCharts ? (
								<BarGraph
									data={state.pathbooksB15.map((x, y) => ({ ...x, range: y + 1 }))}
									label={t("stats.topBooksHighSchool")}
									ype="pathbooks"
								/>
							) : (
								<>
									<Typography.Title level={4}>{t("stats.topBooksHighSchool")}</Typography.Title>
									<div style={{ maxHeight: 400 }}>
										<UseTable data={state.pathbooksB15.map((x, y) => ({ ...x, range: y + 1 }))} type="pathbook" />
									</div>
								</>
							)}
						</Card>
					</Col>
				)}
				{!!state.pathbooksA.length && (
					<Col xl={showCharts ? 24 : 12} xs={24}>
						<Card loading={loading} style={{ width: "100%" }}>
							{showCharts ? (
								<BarGraph data={state.pathbooksA.map((x, y) => ({ ...x, range: y + 1 }))} label={t("stats.topBooksPublic")} type="pathbooks" />
							) : (
								<>
									<Typography.Title level={4}>{t("stats.topBooksPublic")}</Typography.Title>
									<div style={{ maxHeight: 400 }}>
										<UseTable
											data={state.pathbooksA.filter(x => x.classification === "A").map((x, y) => ({ ...x, range: y + 1 }))}
											type="pathbook"
										/>
									</div>
								</>
							)}
						</Card>
					</Col>
				)}
			</Row>
		</>
	);
};
export default Stats;
