import React from "react";
import propTypes from "prop-types";
import { Modal, Button, Form, Input, Row, Col, Checkbox } from "antd";
import { functions } from "../../firebase";
import { useTranslation } from "react-i18next";
import "../../translations/index";

const ModalFunction = props => {
	const [form] = Form.useForm();
	const [loading, setLoading] = React.useState(false);
	const { t } = useTranslation();

	const save = values => {
		setLoading(true);
		functions()
			.httpsCallable("school-createTeacher")({
				...values,
			})
			.then(() => {
				props.close();
				props.reload();
				form.resetFields();
				return setLoading(false);
			})
			.catch(e => {
				setLoading(false);
				Modal.error({ title: "Algo salio mal...", content: e.message });
			});
	};

	const cancel = () => {
		form.resetFields();
		props.close();
	};

	return (
		<Modal
			closable={false}
			footer={[
				<Button disabled={loading} key={1} onClick={cancel}>
					{t("cancel")}
				</Button>,
				<Button key={2} loading={loading} onClick={form.submit} type={"primary"}>
					{t("save")}
				</Button>,
			]}
			forceRender
			keyboard={!loading}
			maskClosable={!loading}
			onCancel={props.close}
			title={t("teacher")}
			visible={props.visible}>
			<Form form={form} layout={"vertical"} onFinish={save}>
				<Form.Item label={t("name")} name={"name"} rules={[{ required: true, message: t("required") }]}>
					<Input />
				</Form.Item>
				<Row gutter={10}>
					<Col span={12}>
						<Form.Item label={t("email")} name={"email"} rules={[{ required: true, message: t("required"), type: "email" }]}>
							<Input />
						</Form.Item>
					</Col>
					<Col span={12}>
						<Form.Item label={t("password")} name={"password"} rules={[{ required: true, min: 6, message: t("passwordSafe") }]}>
							<Input />
						</Form.Item>
					</Col>
				</Row>
				<Form.Item name={"schoolAdmin"} valuePropName={"checked"}>
					<Checkbox>{t("teacherAdminAccount")}</Checkbox>
				</Form.Item>
			</Form>
		</Modal>
	);
};

ModalFunction.propTypes = {
	close: propTypes.func,
	reload: propTypes.func,
	visible: propTypes.bool,
};

export default ModalFunction;
