import React from "react";

const EmptyLock = props => {
	return (
		<svg id="prefix__Capa_1" viewBox="0 0 500 500" x={0} xmlSpace="preserve" xmlns="http://www.w3.org/2000/svg" y={0} {...props}>
			<style>{".prefix__st0{fill:#414141}.prefix__st2{fill:#9b4e23}.prefix__st3{fill:#f5c240}.prefix__st4{fill:#f3f3f3}"}</style>
			<path
				className="prefix__st0"
				d="M315.2 193.3l-14.9-.2.6-40.4c0-29.7-24.3-54-54.1-54s-54.1 24.3-54.1 54.1l-.6 40.5-14.9-.2.6-40.4c0-37.9 30.9-68.8 68.9-68.8s68.9 30.9 68.9 68.9l-.4 40.5z"
			/>
			<path
				d="M316.6 374.7H178.7c-18.7 0-33.9-15.2-33.9-33.9V207.6c0-18.7 15.2-33.9 33.9-33.9h137.9c18.7 0 33.9 15.2 33.9 33.9v133.2c0 18.7-15.2 33.9-33.9 33.9z"
				fill="#d78228"
			/>
			<path className="prefix__st2" d="M350.5 340.9c0 18.7-15.2 33.9-33.9 33.9H178.7s113.8-1.3 150-14.6c22.3-8.1 21.8-19.3 21.8-19.3z" />
			<path className="prefix__st2" d="M144.6 340.9c0 18.7 15.2 33.9 33.9 33.9h137.9s-113.8-1.3-150-14.6c-22.3-8.1-21.8-19.3-21.8-19.3z" />
			<g id="prefix__XMLID_81_" transform="rotate(23.976 414.476 276.39)">
				<path
					className="prefix__st3"
					d="M291.7 118.2c-.4.9-1.4 1.4-2.4 1.2-6.3-1-14.6-2.3-14.6-2.3s-3.8 7.5-6.7 13.1c-.4.9-1.4 1.4-2.4 1.2s-1.7-.9-1.9-1.9c-1-6.3-2.3-14.6-2.3-14.6s-8.3-1.3-14.6-2.3c-1-.2-1.7-.9-1.9-1.9s.3-1.9 1.2-2.4c5.7-2.9 13.1-6.7 13.1-6.7s-1.3-8.3-2.3-14.6c-.2-1 .3-1.9 1.2-2.4.9-.4 1.9-.3 2.7.4l10.4 10.4s7.5-3.8 13.1-6.7c.9-.4 1.9-.3 2.6.4.7.7.9 1.8.4 2.6-2.9 5.7-6.7 13.1-6.7 13.1l10.4 10.4c1 1.1 1.1 2.2.7 3z"
					id="prefix__XMLID_82_"
				/>
			</g>
			<g id="prefix__XMLID_79_" transform="matrix(.59546 .26482 -.26482 .59546 38.457 -43.38)">
				<path
					className="prefix__st3"
					d="M753 282c.5 1.5 0 3.1-1.3 4-4.6 3.3-9.2 6.7-9.2 6.7s1.8 5.4 3.5 10.8c.5 1.5 0 3.1-1.3 4s-3 .9-4.3 0c-4.6-3.3-9.2-6.7-9.2-6.7s-4.6 3.4-9.2 6.7c-1.3.9-3 .9-4.3 0s-1.8-2.6-1.3-4c1.7-5.4 3.5-10.8 3.5-10.8s-4.6-3.3-9.2-6.7c-1.3-.9-1.8-2.6-1.3-4 .5-1.5 1.9-2.5 3.4-2.5h11.3s1.8-5.4 3.5-10.8c.5-1.5 1.9-2.5 3.4-2.5 1.6 0 3 1 3.4 2.5 1.7 5.4 3.5 10.8 3.5 10.8h11.4c1.8 0 3.2 1 3.7 2.5z"
					id="prefix__XMLID_80_"
				/>
			</g>
			<g id="prefix__XMLID_76_" transform="matrix(.59546 .26482 -.26482 .59546 38.457 -43.38)">
				<path
					className="prefix__st3"
					d="M228.6 321.3c-.3-.8 0-1.6.7-2.1 2.4-1.8 4.8-3.5 4.8-3.5s-.9-2.9-1.8-5.7c-.3-.8 0-1.6.7-2.1s1.6-.5 2.2 0c2.4 1.8 4.8 3.5 4.8 3.5s2.4-1.8 4.8-3.5c.7-.5 1.6-.5 2.2 0 .7.5.9 1.3.7 2.1-.9 2.8-1.8 5.7-1.8 5.7s2.4 1.8 4.8 3.5c.7.5.9 1.3.7 2.1-.3.8-1 1.3-1.8 1.3h-6s-.9 2.9-1.8 5.7c-.3.8-1 1.3-1.8 1.3s-1.6-.5-1.8-1.3c-.9-2.8-1.8-5.7-1.8-5.7h-6c-.9 0-1.6-.5-1.8-1.3z"
					id="prefix__XMLID_78_"
				/>
			</g>
			<g id="prefix__XMLID_74_" transform="matrix(.59546 .26482 -.26482 .59546 38.457 -43.38)">
				<path
					className="prefix__st3"
					d="M285 704.1c-.3-.8 0-1.6.7-2.1 2.4-1.8 4.8-3.5 4.8-3.5s-.9-2.9-1.8-5.7c-.3-.8 0-1.6.7-2.1s1.6-.5 2.2 0c2.4 1.8 4.8 3.5 4.8 3.5s2.4-1.8 4.8-3.5c.7-.5 1.6-.5 2.2 0 .7.5.9 1.3.7 2.1-.9 2.8-1.8 5.7-1.8 5.7s2.4 1.8 4.8 3.5c.7.5.9 1.3.7 2.1-.3.8-1 1.3-1.8 1.3h-6s-.9 2.9-1.8 5.7c-.3.8-1 1.3-1.8 1.3s-1.6-.5-1.8-1.3c-.9-2.8-1.8-5.7-1.8-5.7h-6c-.8 0-1.5-.5-1.8-1.3z"
					id="prefix__XMLID_75_"
				/>
			</g>
			<path
				className="prefix__st0"
				d="M85.3 364c-9.9 11.6-25.2 36.4-30 57.5-2.5 11.1-2 21.2 3.1 28.1 5.2 6.9 15.1 11 32.1 9.4 34.7-3.2 65.3-29 91.7-49.9l1.6-1.2-2.5-3.1-1.6 1.2c-25.8 20.5-55.6 45.9-89.6 49.1-15 1.4-24-1.7-28.5-7.8-4.6-6.1-4.6-15.1-2.4-24.9 4.6-20.5 19.6-44.6 29.2-55.8l1.3-1.5-3-2.6-1.4 1.5z"
				id="prefix__XMLID_73_"
			/>
			<path
				className="prefix__st0"
				d="M332.3 114.2c20.9-12.6 43.8-21.7 68.4-21.2 37.2.8 70.4 27.3 59.3 67.9-11.9 43.3-55.1 78.4-85.8 108.8l-1.4 1.4 2.8 2.8 1.4-1.4c31.2-30.9 74.8-66.7 86.9-110.6 12-43.4-23.2-72.1-63-72.9-25.4-.5-49.1 8.9-70.7 22l2.1 3.2z"
				id="prefix__XMLID_72_"
			/>
			<path
				className="prefix__st0"
				d="M129.4 261.4C90 298.9 52.2 339.3 33.5 390.9l-.7 1.9-3.8-1.4.7-1.9c19-52.5 57.7-93.7 97.8-131.9l1.9 3.8z"
				id="prefix__XMLID_71_"
			/>
			<path
				className="prefix__st2"
				d="M264.9 260c0-10.1-8.2-18.4-18.4-18.4-10.1 0-18.4 8.2-18.4 18.4 0 6.6 3.5 12.4 8.7 15.6l-4.7 21.8h30.1s-3.4-13.7-5.5-22.1c5-3.4 8.2-9 8.2-15.3z"
			/>
			<path
				className="prefix__st4"
				d="M333.4 199.3c-1-5.4-2.7-10.4-7.8-13-4.7-2.4-10.3-2-15.4-2-6.3-.1-12.7-.1-19-.2-6.2-.1-7.3 9.6-1 9.7 5.1 0 10.1.1 15.2.1 4.5 0 9.2-.4 13.6.4 4.1.7 4.4 4.1 5 7.7.8 4.8.9 9.5.4 14.4-.6 6.3 9.1 7.3 9.7 1 .6-6.1.5-12.2-.7-18.1zM280.2 189.4c-.3-2.8-2.7-4.3-5.4-4.3-2.6 0-4.6 2.9-4.3 5.4.3 2.8 2.7 4.3 5.4 4.3 2.6 0 4.5-3 4.3-5.4z"
			/>
		</svg>
	);
};

export default EmptyLock;
