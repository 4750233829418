import React from "react";
import propTypes from "prop-types";
import SmartTable from "@offset-dev/antd-smart-table";
import { Tag } from "antd";
import moment from "moment";
import { renderReadingTime } from "../utils/readingTime";
import { useTranslation } from "react-i18next";
import "../translations/index";

const UseTable = props => {
	const { t } = useTranslation();
	const columns = [
		{
			dataIndex: "range",
			key: "range",
			width: 50,
		},
		{
			title: props.type === "student" ? t("student") : t("useTable.bookName"),
			render: x => (props.type === "student" ? <a href={`estudiantes/${x.id}/estadisticas`}>{x.name}</a> : x.name),
		},

		{
			title: t("useTable.reads"),
			dataIndex: "count",
			key: "count",
			width: 90,
		},
	];
	const columsInteractions = [
		{
			title: t("useTable.date"),
			dataIndex: "date",
			key: "date",
		},

		{
			title: t("useTable.interactions"),
			dataIndex: "interactions",
			key: "interactions",
		},
	];
	const renderClasification = x => {
		if (x === "A" || x === "AA") return <Tag color="gold">{t("primary")}</Tag>;
		if (x === "B") return <Tag color="green">{t("middleSchool")}</Tag>;
		if (x === "B15") return <Tag color="gold">{t("highSchool")}</Tag>;
	};

	if (props.type === "pathbook")
		columns.push({ title: t("useTable.educationLevel"), dataIndex: "classification", key: "classification", render: renderClasification });
	if (props.hideRange) delete columns[0];
	if (props.showReadingTime) {
		props.interactions
			? columsInteractions.push({ title: t("useTable.totalTime"), dataIndex: "readingTime", key: "readingTime", render: renderReadingTime })
			: columns.push({ title: t("useTable.totalTime"), dataIndex: "readingTime", key: "readingTime", render: renderReadingTime });
	}
	if (props.showLastReadingDate) columns.push({ title: t("useTable.lastDate"), dataIndex: "lastRead", key: "lastRead", render: t => moment(t).format("ll") });
	return <SmartTable columns={props.interactions ? columsInteractions : columns} dataSource={props.data} scroll={{ y: props.scrolly || 300 }} />;
};

UseTable.propTypes = {
	data: propTypes.array,
	hideRange: propTypes.bool,
	interactions: propTypes.bool,
	scrolly: propTypes.number,
	showLastReadingDate: propTypes.bool,
	showReadingTime: propTypes.bool,
	type: propTypes.string,
};

export default UseTable;
