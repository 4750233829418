import React from "react";
import { Card, Row, Col, Typography, Avatar, Spin } from "antd";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import moment from "moment";
import "moment/locale/es";
import "../translations/index";
import { firestore } from "../firebase";
import { Title } from "../components/useChart";
import colors from "../styles/colors";

moment.locale("es");

const HomePage = () => {
	const school = useSelector(state => state.school);

	const { t } = useTranslation();

	const [state, setState] = React.useState({
		chart: [],
		students: 0,
		teachers: 0,
		licenses: 0,
		licensesRemaining: 0,
		active: true,
		expires: "-",
		loading: true,
	});

	const load = () => {
		const asyncLoad = async () => {
			const data = await firestore()
				.doc("schools/" + school.id)
				.get();
			const pathbooksInfo = await firestore().doc("config/pathbooksInfo").get();

			return setState({
				students: data.data().students ?? 0,
				teachers: data.data().teachers ?? 0,
				licenses: data.data().licenses,
				licensesRemaining: data.data().licensesRemaining,
				active: data.data().active,
				expires: moment(data.data().expires.toDate()).format("lll"),
				loading: false,
				bachilerato: pathbooksInfo.data().bachilerato,
				bachileratoEN: pathbooksInfo.data().bachileratoEN,
				biographies: pathbooksInfo.data().biographies,
				ebooks: pathbooksInfo.data().ebooks,
				kinder: pathbooksInfo.data().kinder,
				kinderEN: pathbooksInfo.data().kinderEN,
				secundaria: pathbooksInfo.data().secundaria,
				secundariaEN: pathbooksInfo.data().secundariaEN,
				pathbooksTotal: pathbooksInfo.data().total,
			});
		};

		if (school) asyncLoad().catch();
	};

	React.useEffect(load, [school]);

	return (
		<>
			<Row justify="center" style={{ marginTop: 5, marginBottom: 20 }}>
				<Col>
					<Avatar shape={"square"} size={110} src={school?.logo} style={{ fontSize: "90px" }}>
						{school?.name.charAt(0)}
					</Avatar>
				</Col>
				{!state.loading && (
					<Col style={{ marginLeft: 20, marginBottom: 20 }}>
						<Typography.Title level={4} style={{ marginTop: 10 }}>
							{school?.name}
						</Typography.Title>
						<Typography.Text>
							{t("home.status")}
							<span style={{ color: colors.primary, fontWeight: "bold", marginRight: 10 }}>
								{state.active ? t("home.active") : t("home.inactive")}
							</span>
							{t("space")}
							{t("home.termLicense")}
							<span style={{ color: colors.primary, fontWeight: "bold" }}>{state.expires}</span>
						</Typography.Text>
						<br />
						<Typography.Text style={{ marginTo: 10 }}>
							{t("home.license")} <span style={{ color: colors.primary, fontWeight: "bold" }}>{state.licensesRemaining}</span> de {"  "}
							<span style={{ color: colors.primary, fontWeight: "bold" }}> {state.licenses}</span>
						</Typography.Text>
					</Col>
				)}
			</Row>

			{state.loading ? (
				<Row justify="center">
					<div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
						<Spin size="large" />
						<Title>{t("loading")}</Title>
					</div>
				</Row>
			) : (
				<>
					<Row gutter={[15, 15]}>
						<Col md={8} xs={24}>
							<Card loading={state.loading} style={{ backgroundColor: colors.stat1 }}>
								<Typography.Title level={4} style={{ color: "#fff" }}>
									{t("studentsText")}
								</Typography.Title>
								<Typography.Title style={{ margin: 0, fontSize: 20, color: "#fff" }}>{state.students}</Typography.Title>
							</Card>
						</Col>
						<Col md={8} xs={24}>
							<Card loading={state.loading} style={{ backgroundColor: colors.stat2 }}>
								<Typography.Title level={4} style={{ color: "#fff" }}>
									{t("teachersText")}
								</Typography.Title>
								<Typography.Title style={{ margin: 0, fontSize: 20, color: "#fff" }}>{state.teachers}</Typography.Title>
							</Card>
						</Col>
						<Col md={8} xs={24}>
							<Card loading={state.loading} style={{ backgroundColor: colors.stat3 }}>
								<Typography.Title level={4} style={{ color: "#fff" }}>
									{t("home.groups")}
								</Typography.Title>
								<Typography.Title style={{ margin: 0, fontSize: 20, color: "#fff" }}>{school?.groups?.length}</Typography.Title>
							</Card>
						</Col>
					</Row>
					<Typography.Title level={4} style={{ marginTop: 15, marginBottom: 10 }}>
						{t("home.books")}
					</Typography.Title>
					<Row gutter={[15, 15]}>
						<Col md={8} xs={24}>
							<Card loading={state.loading} style={{ backgroundColor: colors.stat5 }}>
								<Typography.Title level={4} style={{ color: "#fff" }}>
									{t("pathbooks")}
								</Typography.Title>
								<Typography.Title style={{ margin: 0, fontSize: 20, color: "#fff" }}>{state.pathbooksTotal}</Typography.Title>
							</Card>
						</Col>
						<Col md={8} xs={24}>
							<Card loading={state.loading} style={{ backgroundColor: colors.stat7 }}>
								<Typography.Title level={4} style={{ color: "#fff" }}>
									{t("home.biography")}
								</Typography.Title>
								<Typography.Title style={{ margin: 0, fontSize: 20, color: "#fff" }}>{state.biographies}</Typography.Title>
							</Card>
						</Col>
						<Col md={8} xs={24}>
							<Card loading={state.loading} style={{ backgroundColor: colors.stat8 }}>
								<Typography.Title level={4} style={{ color: "#fff" }}>
									{t("home.ebooks")}
								</Typography.Title>
								<Typography.Title style={{ margin: 0, fontSize: 20, color: "#fff" }}>{state.ebooks}</Typography.Title>
							</Card>
						</Col>
					</Row>
					<Typography.Title level={4} style={{ marginTop: 15, marginBottom: 10 }}>
						{t("home.libraryGrade")}
					</Typography.Title>
					<Row gutter={[15, 15]}>
						<Col md={8} xs={24}>
							<Card loading={state.loading} style={{ backgroundColor: colors.stat9 }}>
								<Row>
									<Col span={24}>
										<Typography.Title level={4} style={{ color: "#fff" }}>
											{t("home.primaryKinder")}
										</Typography.Title>
										<Typography.Title style={{ margin: 0, fontSize: 20, color: "#fff" }}>{state.kinder}</Typography.Title>
									</Col>
									<Col span={24} style={{ marginTop: 10 }}>
										<Typography.Title level={5} style={{ color: "#fff" }}>
											{t("home.english")}
										</Typography.Title>
										<Typography.Title style={{ margin: 0, fontSize: 18, color: "#fff" }}>{state.kinderEN}</Typography.Title>
									</Col>
								</Row>
							</Card>
						</Col>
						<Col md={8} xs={24}>
							<Card loading={state.loading} style={{ backgroundColor: colors.stat10 }}>
								<Row>
									<Col span={24}>
										<Typography.Title level={4} style={{ color: "#fff" }}>
											{t("middleSchool")}
										</Typography.Title>
										<Typography.Title style={{ margin: 0, fontSize: 20, color: "#fff" }}>{state.secundaria}</Typography.Title>
									</Col>
									<Col span={24} style={{ marginTop: 10 }}>
										<Typography.Title level={5} style={{ color: "#fff" }}>
											{t("home.english")}
										</Typography.Title>
										<Typography.Title style={{ margin: 0, fontSize: 18, color: "#fff" }}>{state.secundariaEN}</Typography.Title>
									</Col>
								</Row>
							</Card>
						</Col>
						<Col md={8} xs={24}>
							<Card loading={state.loading} style={{ backgroundColor: colors.stat11 }}>
								<Row>
									<Col span={24}>
										<Typography.Title level={4} style={{ color: "#fff" }}>
											{t("highSchool")}
										</Typography.Title>
										<Typography.Title style={{ margin: 0, fontSize: 20, color: "#fff" }}>{state.bachilerato}</Typography.Title>
									</Col>
									<Col span={24} style={{ marginTop: 10 }}>
										<Typography.Title level={5} style={{ color: "#fff" }}>
											{t("home.english")}
										</Typography.Title>
										<Typography.Title style={{ margin: 0, fontSize: 18, color: "#fff" }}>{state.bachileratoEN}</Typography.Title>
									</Col>
								</Row>
							</Card>
						</Col>
					</Row>
				</>
			)}
		</>
	);
};

export default HomePage;
