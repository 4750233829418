const initialState = {
	loading: true,
	reads: 0,
	interactions: 0,
	ratio: 0,
	pathbooksRead: [],
	studentsReading: [],
	topPathbooks: [],
	bestStudents: [],
	worstStudents: [],
	pathbooksA: [],
	pathbooksB: [],
	pathbooksB15: [],
	pathbooksAA: [],
	academicPathbooks: [],
	range: null,
	fetching: false,
};

const StatsState = (state = initialState, action) => {
	switch (action.type) {
		case "SET_STAT":
			return action.payload;
		case "CLEAR_STAT":
			return initialState;
		case "FETCHING":
			return {
				...state,
				fetching: true,
			};
		default:
			return state;
	}
};

export default StatsState;
