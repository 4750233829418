import { firestore } from "../../firebase";

export const getSchool = () => {
	return async (dispatch, getState) => {
		const uid = getState().user?.uid;

		const userDoc = await firestore().doc(`users/${uid}`).get();

		if (!userDoc.data().school) {
			return Promise.reject(Error("Este usuario no tiene escuela asignada"));
		}

		const school = userDoc.data().school;
		const schoolDoc = await school.get();

		await dispatch({
			type: "SET_SCHOOL",
			payload: {
				id: schoolDoc.id,
				name: schoolDoc.data().name,
				logo: schoolDoc.data().logo,
				groups: schoolDoc.data().groups ?? [],
			},
		});
	};
};

export const removeSchool = () => {
	return dispatch => {
		dispatch({ type: "REMOVE_SCHOOL" });
	};
};
