import { Input, Modal } from "antd";
import { functions } from "../firebase";
import i18n from "i18next";
import "../translations/index";

const updatePassword = uid => {
	let password;
	Modal.confirm({
		title: i18n.t("updatePassword.updatePassword"),
		content: <Input onChange={e => (password = e.currentTarget.value)} />,
		okText: i18n.t("updatePassword.update"),
		cancelText: i18n.t("updatePassword.cancel"),
		onOk: async () => {
			if (!password || password.length <= 5) return Modal.warning({ title: i18n.t("error"), content: i18n.t("passwordSafe") });
			return await functions()
				.httpsCallable("school-updatePassword")({ user: uid, password })
				.catch(e => Modal.warning({ title: i18n.t("error"), content: e.message }));
		},
	});
};

export default updatePassword;
