/* eslint-disable react-hooks/rules-of-hooks */
import React, { useState, useEffect } from "react";
import propTypes from "prop-types";
import { Collapse, Table, Button } from "antd";
import { Title } from "../components/useChart";
import { ArrowUpOutlined, ArrowDownOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import "../translations/index";

// mostrar interactiones y tiempo de lectura en la misma grafica.

// Especificar que se promedian solo los dias que hubo lectura
const { Column } = Table;
const readingTimeChart = props => {
	const [data, setData] = useState({});
	const [showAll, setShowAll] = useState(false);
	const [arrayData, setArrayData] = useState([]);
	const { t } = useTranslation();
	const readingTimeFormatter = t => {
		if (t >= 60) {
			const hours = Math.trunc(t / 60);
			const minutes = Math.trunc(t % 60);
			return `${hours} ${hours === 1 ? "hora" : "horas"} con ${minutes} ${minutes === 1 ? "minuto" : "minutos"}`;
		}

		return `${t} ${t === 1 ? "minuto" : "minutos"}`.replace(".", ":");
	};

	useEffect(() => {
		if (!Object.keys(props.readingTime).length) return;
		const map = {};
		Object.keys(props.readingTime).forEach(date => {
			const data = Object.keys(props.readingTime[date]).map(pathbookID => {
				return {
					...props.readingTime[date][pathbookID],
				};
			});
			map[date] = data;
		});
		setData(map);
		setArrayData(Object.keys(map).splice(0, 10));
	}, [props.readingTime, props.allPathbooks]);

	const view = () => {
		if (showAll) {
			setArrayData(Object.keys(data).splice(0, 10));
		} else {
			setArrayData(Object.keys(data));
		}
		setShowAll(s => !s);
	};

	return (
		<>
			<Title>Tiempo de lectura</Title>
			<div style={{ marginBottom: 10 }} />
			{arrayData.map(date => {
				return (
					<>
						<Collapse key={date}>
							<Collapse.Panel header={date}>
								<Table dataSource={data[date]}>
									<Column dataIndex="name" key="name" title="Pathbook" />
									<Column
										dataIndex="readingTime"
										key="readingTime"
										render={render => readingTimeFormatter(render)}
										title={t("readingTimeChart.readingtime")}
									/>
								</Table>
							</Collapse.Panel>
						</Collapse>
					</>
				);
			})}
			{arrayData.length > 10 && (
				<Button block icon={showAll ? <ArrowUpOutlined /> : <ArrowDownOutlined />} onClick={view} type="dashed">
					{showAll ? t("readingTimeChart.seeLess") : t("readingTimeChart.seeAllDate")}
				</Button>
			)}
		</>
	);
};

readingTimeChart.propTypes = {
	allPathbooks: propTypes.array,
	readingTime: propTypes.object,
};

export default readingTimeChart;
