import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Modal, Select, Typography } from "antd";
import { useTranslation } from "react-i18next";
import propTypes from "prop-types";
import "../translations/index";
import { setLanguage } from "../redux/actions/settings";

const LangModal = props => {
	const dispatch = useDispatch();
	const [loading, setLoading] = useState(false);
	const { t, i18n } = useTranslation();
	const settings = useSelector(state => state.settings);
	const [lang, setLang] = useState(settings?.lang);

	const changeLang = async () => {
		await setLoading(true);
		await dispatch(setLanguage(lang));
		await i18n.changeLanguage(lang);
		await setLoading(false);
	};
	React.useEffect(() => {
		changeLang();
		//eslint-disable-next-line
	}, [lang]);
	return (
		<Modal
			cancelText={t("langModal.cancel")}
			okButtonProps={{ loading }}
			okText={t("langModal.confirm")}
			onCancel={() => {
				setLang(settings.lang ?? "es");
				props.onCancel();
			}}
			onOk={props.onCancel}
			visible={props.visible}>
			<Typography.Title>{t("langModal.titleLang")}</Typography.Title>
			<Typography.Paragraph>{t("langModal.description")}</Typography.Paragraph>
			<Select
				onChange={e => {
					setLang(e);
				}}
				style={{ width: "50%" }}
				value={lang ?? "es"}>
				<Select.Option value={"es"}>Español</Select.Option>
				<Select.Option value={"en"}>English</Select.Option>
			</Select>
		</Modal>
	);
};

LangModal.propTypes = {
	onCancel: propTypes.func,
	visible: propTypes.bool,
};

export default LangModal;
