import async from "async";
import { Modal as RawModal, Select, Input, Row, Col } from "antd";
import { functions } from "../../firebase";
import React from "react";
import colors from "../../styles/colors";

export const updateStudents = (teachers: Array, groups: Array, students: Array) => {
	return new Promise(resolve => {
		let selectTeachers, grade, group;
		RawModal.confirm({
			title: "Actualizar Estudiantes",
			content: (
				<>
					<p>Esto cambiara la configuración a todos los estudiantes que hayas elegido</p>
					Maestro: <br />
					<Select mode={"multiple"} onChange={e => (selectTeachers = e)} style={{ width: "100%" }}>
						{teachers.map(x => {
							return <Select.Option value={x.id}>{x.name}</Select.Option>;
						})}
					</Select>
					Nivel: <br /> {/*Key is 'grade'*/}
					<Select onChange={e => (grade = e)} style={{ width: "100%" }}>
						<Select.Option value={"AA"}>Kinder</Select.Option>
						<Select.Option value={"A"}>Primaria</Select.Option>
						<Select.Option value={"B"}>Secundaria</Select.Option>
						<Select.Option value={"B15"}>Bachilerato</Select.Option>
					</Select>
					Grupo: <br />
					<Select onChange={e => (group = e)} style={{ width: "100%" }}>
						{groups.map(x => (
							<Select.Option key={x} value={x}>
								{x}
							</Select.Option>
						))}
					</Select>
				</>
			),
			okText: "Actualizar",
			cancelText: "Cancelar",
			onOk: async () => {
				await async.each(
					students,
					async.asyncify(async student => {
						await functions().httpsCallable("school-updateStudent")({ student, teachers: selectTeachers, grade, group });
					})
				);
				resolve();
			},
		});
	});
};

export const updateStudent = student => {
	let name = student.name;

	return new Promise(resolve => {
		RawModal.confirm({
			title: "Actualizar nombre del estudiante",
			content: (
				<>
					<p style={{ color: colors.primary, opacity: 0.8, fontSize: 13 }}>
						Al modificar el nombre del usuario el nombre anterior quedará eliminado y remplazado por el nuevo nombre
					</p>
					<Row>
						<Col span={24} style={{ marginBottom: 10 }}>
							Nombre <br />
							<Input defaultValue={name} onChange={e => (name = e.target.value)} placeholder="Nombree del estudiante" />
						</Col>
					</Row>
				</>
			),
			okText: "Actualizar",
			cancelText: "Cancelar",
			onOk: async () => {
				await functions().httpsCallable("school-updateStudent")({ student: student.id, name });
				resolve();
			},
		});
	});
};
