import React from "react";
import propTypes from "prop-types";
import { Modal, Button, Form, Input, Row, Col, Select, Typography } from "antd";
import { functions } from "../../firebase";
import { useSelector } from "react-redux";
import colors from "../../styles/colors";
import { useTranslation } from "react-i18next";
import "../../translations/index";

const ModalFunction = props => {
	const [form] = Form.useForm();
	const school = useSelector(state => state.school);
	const [loading, setLoading] = React.useState(false);
	const { t } = useTranslation();

	const save = values => {
		setLoading(true);
		functions()
			.httpsCallable("school-createStudent")({
				...values,
			})
			.then(() => {
				props.close();
				props.reload();
				form.resetFields(["name", "username", "password"]);
				return setLoading(false);
			})
			.catch(e => {
				setLoading(false);
				Modal.error({ title: t("studentsModal.wrong"), content: e.message });
			});
	};

	const cancel = () => {
		form.resetFields(["name", "username", "password"]);
		props.close();
	};

	return (
		<Modal
			closable={false}
			footer={[
				<Button disabled={loading} key={1} onClick={cancel}>
					{t("cancel")}
				</Button>,
				<Button key={2} loading={loading} onClick={form.submit} type={"primary"}>
					{t("save")}
				</Button>,
			]}
			forceRender
			keyboard={!loading}
			maskClosable={!loading}
			onCancel={props.close}
			title={"Agregar Estudiante"}
			visible={props.visible}>
			<Row>
				<Typography.Text style={{ fontWeight: "bold", marginBottom: 10, opacity: 0.8 }}>
					{t("studentsModal.dataStudent")} <span style={{ color: colors.primary, fontWeight: "bold" }}> *</span> {t("studentsModal.obligatory")}
				</Typography.Text>
			</Row>
			<Form form={form} layout={"vertical"} onFinish={save}>
				<Row gutter={10}>
					<Col span={24}>
						<Form.Item label={t("name")} name={"name"} rules={[{ required: true, message: t("requirid") }]}>
							<Input />
						</Form.Item>
					</Col>
				</Row>
				<Row gutter={10}>
					<Col span={12}>
						<Form.Item label={t("studentsModal.academicLevel")} name={"grade"} rules={[{ required: true, message: t("requirid") }]}>
							<Select>
								<Select.Option value={"AA"}>{t("kinder")}</Select.Option>
								<Select.Option value={"A"}>{t("primary")}</Select.Option>
								<Select.Option value={"B"}>{t("middleSchool")}</Select.Option>
								<Select.Option value={"B15"}>{t("highSchool")}</Select.Option>
							</Select>
						</Form.Item>
					</Col>
					<Col span={12}>
						<Form.Item label={"Grupo"} name={"group"}>
							<Select>
								{school?.groups.map(x => {
									return (
										<Select.Option key={x} value={x}>
											{x}
										</Select.Option>
									);
								})}
							</Select>
						</Form.Item>
					</Col>
				</Row>
				<Row gutter={10}>
					<Col span={24}>
						<Form.Item label={t("teacher", { count: 10 })} name={"teachers"}>
							<Select mode={"multiple"}>
								{props.teachers.map(x => {
									return (
										<Select.Option key={x.id} value={x.id}>
											{x.name}
										</Select.Option>
									);
								})}
							</Select>
						</Form.Item>
					</Col>
				</Row>
				<Row gutter={10}>
					<Col span={12}>
						<Form.Item label={t("studentsModal.user")} name={"username"} rules={[{ required: true, message: t("required") }]}>
							<Input />
						</Form.Item>
					</Col>
					<Col span={12}>
						<Form.Item label={t("password")} name={"password"} rules={[{ required: true, min: 6, message: t("passwordSafe") }]}>
							<Input />
						</Form.Item>
					</Col>
				</Row>
			</Form>
		</Modal>
	);
};

ModalFunction.propTypes = {
	close: propTypes.func,
	reload: propTypes.func,
	teachers: propTypes.array,
	visible: propTypes.bool,
};

export default ModalFunction;
