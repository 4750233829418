import { combineReducers } from "@reduxjs/toolkit";
import user from "./user";
import school from "./school";
import stats from "./stats";
import settings from "./settings";

export default combineReducers({
	user,
	school,
	stats,
	settings,
});
