import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Avatar, Button, Divider, Modal, Select, Typography, Upload } from "antd";
import { TeamOutlined, UploadOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";

import upload from "./students/upload";
import { firestore, storage } from "../firebase";
import { getSchool } from "../redux/actions/school";
import { useHistory } from "react-router-dom";
import "../translations/index";

const Config = () => {
	const dispatch = useDispatch();
	const school = useSelector(state => state.school);
	const user = useSelector(state => state.user);
	const [uploadingLogo, setUploadingLogo] = React.useState(false);
	const [processing, setProcessing] = React.useState(false);
	const history = useHistory();
	const { t } = useTranslation();

	React.useEffect(() => {
		if (!user.schoolAdmin) history.push("/");
	}, [user, history]);

	const firstLetter = word => {
		return word[0];
	};

	const getAcronym = str => {
		const words = str.split(" ");
		const acr = words.map(firstLetter);
		return acr.join("").toUpperCase();
	};

	// eslint-disable-next-line react/prop-types
	const Groups = ({ gr, onChange }) => {
		const [groups, setGroups] = React.useState(gr);
		return (
			<>
				Grupos: <br />
				<Select
					mode="tags"
					onChange={e => {
						setGroups(e);
						onChange(e);
					}}
					style={{ width: "100%" }}
					value={groups}
				/>
			</>
		);
	};

	const uploadLogo = async f => {
		setUploadingLogo(true);
		const logoRef = storage().ref().child(`schools/${school.id}/logo.png`);
		await logoRef.put(f.file);
		const logo = await logoRef.getDownloadURL();
		await firestore().doc(`schools/${school.id}`).update({
			logo,
		});
		await dispatch({
			type: "UPDATE_SCHOOL",
			payload: {
				logo,
			},
		});
		setUploadingLogo(false);
	};

	const updateGroups = () => {
		let groups = school.groups;
		Modal.confirm({
			title: t("config.updateGroups"),
			content: <Groups gr={groups} onChange={e => (groups = e)} />,
			okText: t("config.update"),
			cancelText: t("cancel"),
			onOk: async () => {
				if (!groups.length) return Modal.warning({ title: t("error"), content: t("config.selectGroup") });
				await firestore().doc(`schools/${school.id}`).update({ groups });
				await dispatch(getSchool());
			},
		});
	};

	const uploadFile = f => {
		setProcessing(true);
		return upload(f).then(err => {
			setProcessing(false);
			return Modal.error({
				width: 500,
				title: t("error"),
				content: err,
			});
		});
	};

	return (
		<>
			<Typography.Title level={1}>{t("config.configuration")}</Typography.Title>

			<Divider />

			<Typography.Title level={4}>{t("school")}</Typography.Title>
			<Avatar shape="square" size={180} src={school?.logo}>
				{getAcronym(school?.name ?? "")}
			</Avatar>
			<br />
			<br />
			<Upload accept={"image/*"} customRequest={uploadLogo} showUploadList={false}>
				<Button icon={<UploadOutlined />} loading={uploadingLogo}>
					{t("config.changeLogo")}
				</Button>
			</Upload>
			<br />
			<br />
			<Button icon={<TeamOutlined />} onClick={updateGroups}>
				{t("config.adjustGroups")}
			</Button>

			<Divider />

			<Typography.Title level={4}>Estudiantes</Typography.Title>
			<Upload accept={".csv"} customRequest={uploadFile} showUploadList={false}>
				<Button icon={<UploadOutlined />} loading={processing}>
					{t("config.import")}
				</Button>
			</Upload>

			<Divider />
		</>
	);
};

export default Config;
