import React from "react";
import { DeleteFilled, KeyOutlined, FileExcelOutlined } from "@ant-design/icons";
import { Col, Row, Button, Tag, Modal as M, Input, Tooltip, Typography } from "antd";
import SmartTable from "@offset-dev/antd-smart-table";
import { useSelector } from "react-redux";
import { CSVLink } from "react-csv";

import { useHistory } from "react-router-dom";

import { useTranslation } from "react-i18next";
import "../../translations/index";

import colors from "../../styles/colors";
import updatePassword from "../../utils/updatePassword";
import { firestore, functions } from "../../firebase";
import Modal from "./modal";
import Header from "../../components/header";
import moment from "moment";

const TeachersPage = () => {
	const school = useSelector(state => state.school);
	const user = useSelector(state => state.user);
	const [modal, setModal] = React.useState(false);
	const [loading, setLoading] = React.useState(true);
	const [teachers, setTeachers] = React.useState([]);
	const [allTeachers, setAllTeachers] = React.useState([]);
	const history = useHistory();
	const { t } = useTranslation();

	const load = () => {
		if (!school) return false;
		setLoading(true);
		firestore()
			.collection(`schools/${school.id}/teachers`)
			.orderBy("name", "desc")
			.get()
			.then(teachers => {
				const data = teachers.docs.map(x => ({ id: x.id, ...x.data() }));
				setAllTeachers(data);
				setTeachers(data);
				return setLoading(false);
			})
			.catch();
	};

	React.useEffect(() => {
		if (!user.schoolAdmin) history.push("/");
	}, [user, history]);

	React.useEffect(load, [school]);

	const editTeacher = (teacher, values) => {
		M.confirm({
			title: t("teachers.editTeacher"),
			content: `${t("teachers.this")} ${teacher ? t("teachers.remove") : t("teachers.give")} acceso de administrador`,
			onOk: async () => {
				await functions().httpsCallable("school-updateTeacher")({
					teacher: values.id,
					schoolAdmin: !teacher,
				});
				load();
			},
		});
	};

	const deleteTeacher = id => {
		M.confirm({
			title: t("teachers.deleteTeacher"),
			content: t("teachers.deleteTeacherConfirm"),
			okText: t("teachers.delete"),
			okButtonProps: {
				danger: true,
			},
			onOk: async () => {
				await functions().httpsCallable("school-deleteTeacher")({
					teacher: id,
				});
				load();
			},
		});
	};

	const onSearch = e => {
		const data = allTeachers.filter(x => x.name.toLowerCase().includes(e.toLowerCase()) || x.email.toLowerCase().includes(e.toLowerCase()));
		setTeachers(data);
	};
	const exportedData = teachers.map(x => ({
		Escuela: school.name,
		Nombre: x.name,
		Email: x.email,
	}));

	return (
		<>
			<Modal close={() => setModal(false)} reload={load} visible={modal} />
			<Row>
				<Col md={12} xs={24}>
					<Header hideArrow title={t("teachersText")} />
				</Col>
				<Col md={12} style={{ textAlign: "right", paddingTop: 6 }} xs={24}>
					<CSVLink data={exportedData} filename={"Maestros.csv"}>
						<Button icon={<FileExcelOutlined />} style={{ marginRight: 5 }}>
							Exportar Maestros
						</Button>
					</CSVLink>
					<Button onClick={() => setModal(true)} type={"primary"}>
						{t("teachers.addTeacher")}
					</Button>
				</Col>
			</Row>
			<Row style={{ margin: "10px 0" }}>
				<Input.Search
					allowClear
					enterButton={t("search", {
						value: moment().format("MMM d, yyyy"),
					})}
					onChange={e => onSearch(e.target.value)}
					onSearch={onSearch}
					placeholder={t("teachers.searchTeacher")}
					size="large"
				/>
			</Row>
			<SmartTable
				columns={[
					{
						title: t("teachersText"),
						defaultSortOrder: "ascend",
						sorter: (a, b) => a.name.localeCompare(b.name),
						dataIndex: "name",
						search: "name",
						width: 200,
						render: (t, values) => (
							<>
								<Row>{t}</Row>
								<Row>
									<Typography.Text onClick={() => window.open("mailto:" + values.email)} style={{ color: colors.primary, cursor: "pointer" }}>
										{values.email}
									</Typography.Text>
								</Row>
							</>
						),
					},

					{
						title: t("teachers.admin"),
						dataIndex: "schoolAdmin",
						render: (x, values) => (
							<Tooltip placement="bottom" title={x ? t("teachers.quitAdmin") : t("teachers.changeAdmin")}>
								<Tag color={x ? "green" : "orange"} onClick={() => editTeacher(x, values)} style={{ cursor: "pointer" }}>
									{x ? "Si" : "No"}
								</Tag>
							</Tooltip>
						),
						width: 100,
					},
					{
						title: t("edit"),
						dataIndex: "schoolAdmin",
						render: (t, values) => (
							<>
								<Button icon={<KeyOutlined />} onClick={() => updatePassword(values.id)} style={{ marginRight: 10 }} />
								<Button icon={<DeleteFilled />} onClick={() => deleteTeacher(values.id)} />
							</>
						),
						width: 160,
					},
				]}
				dataSource={teachers}
				loading={loading}
				rowKey={"id"}
				scroll={{ x: 400 }}
				spanish
			/>
		</>
	);
};

export default TeachersPage;
