import { auth, firestore } from "../../firebase";
import { getSchool, removeSchool } from "./school";
import { clearStats } from "./stats";

export const login = () => {
	return async dispatch => {
		const uid = auth().currentUser.uid;

		const userDoc = await firestore().doc(`users/${uid}`).get();
		if (!userDoc.exists) {
			await dispatch(logout());
			return Promise.reject(Error("No estas autorizado a entrar a esta area"));
		}

		if (userDoc.data().role !== "Principal" && userDoc.data().role !== "Teacher") {
			await dispatch(logout());
			return Promise.reject(Error("No estas autorizado a entrar a esta area"));
		}

		await dispatch({
			type: "LOGIN",
			payload: {
				uid,
				name: userDoc.data().name,
				role: userDoc.data().role,
				schoolAdmin: userDoc.data().schoolAdmin,
			},
		});

		await dispatch(getSchool());
	};
};

export const logout = () => {
	return async dispatch => {
		await auth().signOut();
		await dispatch(removeSchool());
		await dispatch({ type: "LOGOUT" });
		await dispatch(clearStats());
	};
};
