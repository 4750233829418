import i18n from "../../translations";

export const setSettings = (payload: Object) => {
	return async dispatch => {
		dispatch({
			type: "SET_SETTINGS",
			payload,
		});
	};
};

export const setLanguage = lng => {
	return async dispatch => {
		await i18n.changeLanguage(lng);
		dispatch({
			type: "SET_LANGUAGE",
			payload: lng ?? "es",
		});
	};
};
